.upload-logo {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .upload-logo__input {
    flex: 1;
  
    h4 {
      color: #333;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      margin-bottom: 10px;
    }
  
    p {
      color: #333;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  
  .upload-logo__preview {
    border: 1px solid #555;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    height: 158px;
    flex: 1;
  
    p {
      font-weight: 800;
      font-size: 40px;
      color: var(--main-color);
    }
  
    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
  
  .upload-logo__input .input-file {
    margin-top: 30px;
  }
  
  .add-row-inputs {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
    border-radius: 10px;
    height: 50px;
    max-width: 340px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
    color: #fff;
    transition: all 0.3s;
    background: var(--main-color);
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  
    i {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    &:hover {
      background: #4E41AB;
    }
  }
  
  .not-found-distributors {
    margin-top: 20px;
  
    button {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top: 10px;
      border-radius: 10px;
      border: 1px solid #555;
      box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
      transition: all 0.3s;
      color: #333;
      font-family: "Montserrat";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      background: none;
  
      &:hover {
        background: #4E41AB;
        color: #fff;
      }
    }
  }
  
  @media (max-width: 1060px) {
    .upload-logo__input {
      h4 {
        font-size: 16px;
        line-height: 24px;
      }
  
      p {
        font-size: 13px;
        font-weight: 400;
        line-height: 17px;
      }
    }
  
    .upload-logo {
      gap: 10px;
    }
  
    .upload-logo__preview {
      height: 155px;
    }
  }
  
  .logo__preview-mob {
    display: none;
  }
  
  @media (max-width: 480px) {
    .upload-logo {
      flex-direction: column;
    }
  
    .upload-logo__preview {
      flex: auto;
      height: 190px;
    }
  
    .upload-logo__input {
      h4 {
        font-size: 14px;
        line-height: 20px;
      }
  
      p {
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 10px;
      }
    }
  
    .add-row-inputs {
      max-width: 100%;
      margin-bottom: 15px;
      font-size: 13px;
      line-height: 16px;
      height: 40px;
      border-radius: 6px;
  
      svg {
        width: 15px;
        height: 15px;
      }
    }
  
    .not-found-distributors button {
      max-width: 100%;
      margin-bottom: 15px;
      font-size: 13px;
      line-height: 16px;
      height: 40px;
      border-radius: 6px;
    }
  
    .upload-logo__preview {
      display: none;
    }
  
    .logo__preview-mob {
      display: flex;
    }
  
    .upload-logo__input .input-file {
      margin-top: 10px;
      display: flex;
      flex-direction: column-reverse;
  
      .file-name {
        margin-bottom: 0;
        margin-top: 10px;
      }
    }
  }
  