.banner-support {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 80px;
  }
  
  .banner-support__block {
    margin-top: 80px;
    box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
    background: radial-gradient(1414.12% 141.42% at 100% 100%, #8A7DE7 0%, #4E41AB 100%);
    border-radius: 17px;
    box-sizing: border-box;
    padding: 70px 30px;
    text-align: center;
    position: relative;
  }
  
  .banner-support {
    h2 {
      margin-bottom: 10px;
      color: #FFF;
      font-size: 34px;
      font-weight: 700;
      line-height: 40px;
      text-transform: uppercase;
      position: relative;
    }
  
    h3 {
      margin-top: 20px;
      color: #FFF;
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      text-transform: uppercase;
      position: relative;
    }
  
    p {
      margin-top: 7px;
      color: #FFF;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      position: relative;
    }
  }
  
  .banner-support__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    position: relative;
  
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none !important;
      border-radius: 10px;
      background: #FFF;
      max-width: 349px;
      width: 100%;
      height: 66px;
      color: var(--main-color);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      transition: all 0.3s;
  
      &:hover {
        background: #4E41AB;
        color: #fff;
      }
    }
  }
  
  .blob {
    position: absolute;
  }
  
  .blob1 {
    left: 0;
    bottom: 0;
  }
  
  .blob2 {
    right: 0;
    top: 0;
  }
  
  .blob3 {
    right: 0;
    bottom: 0;
  }
  
  @media (max-width: 1060px) {
    .blob {
      width: 40%;
    }
  
    .blob1 {
      left: -75px;
      bottom: 0;
    }
  
    .blob2 {
      right: 30px;
      top: -30px;
    }
  
    .blob3 {
      right: 30px;
      bottom: -40px;
    }
  
    .blob img {
      max-width: 100%;
    }
  
    .banner-support {
      h2 {
        font-size: 24px;
        line-height: 30px;
      }
  
      h3 {
        font-size: 16px;
        line-height: 24px;
      }
  
      p {
        font-size: 14px;
        line-height: 20px;
        font-size: 14px;
        line-height: 20px;
        max-width: 455px;
        width: 100%;
        margin: 0 auto;
        margin-top: 5px;
      }
    }
  
    .banner-support__btn a {
      max-width: 320px;
      height: 50px;
    }
  
    .banner-support__block {
      padding: 55px 30px;
    }
  
    .banner-support {
      padding: 0px 20px 80px 20px;
    }
  }
  
  @media (max-width: 480px) {
    .banner-support {
      padding: 0px 0px 60px 0px;
    }
  
    .blob1 {
      left: 0;
      bottom: 0;
    }
  
    .blob2 {
      right: 0;
      top: 0;
    }
  
    .blob3 {
      right: 0;
      bottom: 0px;
    }
  
    .banner-support__block {
      padding: 35px 20px;
      margin-top: 50px;
      border-radius: 0;
    }
  
    .banner-support {
      h2 {
        font-size: 18px;
        line-height: 26px;
      }
  
      h3 {
        font-size: 13px;
        line-height: 24px;
        margin-top: 15px;
      }
  
      p {
        max-width: 260px;
        font-size: 10px;
        line-height: 16px;
      }
    }
  
    .banner-support__btn a {
      max-width: 100%;
      height: 50px;
    }
  }
  