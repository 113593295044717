.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;


}

.block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  button {
    border: none;
    outline: none;
    border-radius: 10px;
    width: 100%;
    transition: all 0.3s ease 0s;

    @media (any-hover: hover) {
      &:hover {
        background: #cccccca9;
      }
    }

    a {
      display: block;
      padding: 10px 15px;
      width: 100%;
      text-decoration: none;
      color: inherit;
    }
  }
}

.main {
  font-size: 150px;
  color: var(--main-color);
  font-weight: 600;

  @media (max-width:768px) {

    font-size: 100px;
  }

  @media (max-width:567px) {
    font-size: 70px;
  }
}

.second {
  font-size: 20px;
}