.inputs-row {
	display: flex;
	gap: 20px;
	align-items: flex-start;
	position: relative;

	&.pulse {
		.input-block {
			box-shadow: 0 0 0 rgba(138, 125, 231, 0.7); /* Начальный box-shadow */
			animation: pulse 2s infinite; /* Применяем анимацию */
		}
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 rgba(138, 125, 231, 0.7);
	}
	50% {
		box-shadow: 0 0 20px rgba(138, 125, 231, 0.7),
			0 0 40px rgba(138, 125, 231, 0.7);
	}
	100% {
		box-shadow: 0 0 0 rgba(138, 125, 231, 0.7);
	}
}

.remove-row {
	background: none;
	border: none;
	position: absolute;
	right: -20px;
	top: 20px;
	z-index: 2;

	@media (max-width: 768px) {
		position: relative;
		right: 0;
	}

	@media (max-width: 480px) {
		position: absolute;
		right: 7px;
	}

	svg {
		width: 13px;
		height: auto;

		path {
			transition: all 0.3s;
		}
	}

	&:hover path {
		stroke: #4e41ab;
	}
}

.inputs-row .input-block {
	flex: 1;
}

.input-block,
.select-block {
	.input-note-desc {
		max-width: 250px;
	}
}

.max-select {
	.multi-select-stroke__option {
		cursor: default !important;
	}
}

.input-block {
	margin-bottom: 20px;
	position: relative;
	background: #ebf1f8;
	border-radius: 10px;
	min-height: 58px;

	&.no-margin {
		margin-bottom: 0;
	}

	.input-note-desc {
		left: auto;
		right: 0;
	}

	&:focus-within {
		.input-placeholder {
			top: 12px;
			font-size: 12px;
			font-weight: 400;
			line-height: 14px;

			@media (max-width: 480px) {
				top: 8px;
				font-size: 10px;
			}
		}
	}

	&.outline {
		background: #ffffff1a;
		border: 1px solid #ffffff;

		.input-placeholder {
			color: #fff;
		}
		input {
			color: #fff;
		}
	}

	&.small {
		min-height: 50px;

		input {
			min-height: 50px;
		}
	}
}

.input-placeholder {
	position: absolute;
	padding-right: 20px;
	left: 20px;
	top: 20px;
	font-family: 'Montserrat';
	color: #777;
	font-size: 15px;
	font-weight: 400;
	line-height: 18px;
	transition: all 0.3s;
	pointer-events: none;

	overflow: hidden;
	text-overflow: ellipsis;
	width: -webkit-fill-available;
	white-space: nowrap;
	z-index: 1;

	span {
		color: #f00;
	}
}

.input-error {
	position: absolute;
	color: #f00;
	font-size: 12px;
	left: 0;
}

.input-block {
	input {
		font-family: 'Montserrat';
		box-sizing: border-box;
		padding-left: 20px;
		height: 100%;
		color: #333;
		font-size: 15px;
		font-weight: 400;
		line-height: 18px;
		background: none;
		border: none;
		width: 100%;
		outline: none;
		min-height: 58px;
		display: block;
		outline: none;
		z-index: 1;
		padding-right: 40px;
		position: relative;
		padding-top: 15px;
		&:-webkit-autofill,
		&:-internal-autofill-selected {
			-webkit-box-shadow: 0 0 0px 1000px #ebf1f8 inset;
			color: #777 !important;

			border: 1px solid transparent !important;
			transition: background-color 5000s ease-in-out 0s !important;
		}
	}
	input[type='time'] {
		-webkit-appearance: none; /* Для Safari */
		-moz-appearance: textfield; /* Для Firefox */
		appearance: none; /* Для других браузеров */
	}

	input[type='time']::-webkit-inner-spin-button,
	input[type='time']::-webkit-calendar-picker-indicator {
		display: none;
	}
	.special-label {
		display: none;
	}

	.input-phone {
		&.active ~ .input-placeholder {
			top: 12px;
			font-size: 12px;
			font-weight: 400;
			line-height: 14px;
			@media (max-width: 480px) {
				top: 8px;
				font-size: 10px;
			}
		}
	}

	&.disabled {
		background: #eee;
	}

	input {
		&::placeholder {
			font-family: 'Montserrat';
			color: #777;
			font-size: 15px;
			font-weight: 400;
			line-height: 18px;
		}

		&:focus ~ .input-placeholder {
			top: 12px;
			font-size: 12px;
			font-weight: 400;
			line-height: 14px;
		}
	}

	.input-placeholder.active {
		top: 12px;
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;

		@media (max-width: 480px) {
			top: 8px;
		}
	}

	input {
		-webkit-appearance: none; /* Для WebKit-браузеров */
		-moz-appearance: textfield; /* Для Firefox */
		appearance: none; /* Общий стиль */
		&:-webkit-inner-spin-button,
		&:-webkit-calendar-picker-indicator {
			display: none;
			-webkit-appearance: none;
		}
		&::-webkit-calendar-picker-indicator {
			background: none;
		}
		&:focus {
			// padding-top: 15px;
		}

		&.active {
			padding-top: 15px;

			~ .input-placeholder {
				top: 12px;
				font-size: 12px;
				font-weight: 400;
				line-height: 14px;
			}
		}

		&.input-phone:focus {
			padding-top: 0;
		}
	}
}

.input-note {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;

	path {
		transition: all 0.3s;
	}
}

.input-note ~ .input-placeholder {
	padding-right: 35px !important;
}

.manufacturer-col-wrap .input-note:hover path {
	stroke: none;
}

.input-note.tariff-prompt .input-note-desc {
	max-width: 333px;

	@media (max-width: 768px) {
		max-width: 184px;
	}

	@media (max-width: 480px) {
		max-width: 333px;
	}
}

.input-note-desc {
	position: absolute;
	max-width: 530px;
	width: auto;
	box-sizing: border-box;
	padding: 10px;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	border-radius: 5px;
	border: 1px solid #f6f6f6;
	background: #fff;
	color: #333;
	font-size: 11px;
	font-weight: 400;
	line-height: 13px;
	top: -30px;
	right: 50px;
	opacity: 0;
	right: auto;
	left: 20px;
	z-index: -1000;
	transition: all 0.3s;
	width: max-content;
	display: none;

	@media (max-width: 768px) {
		max-width: calc(100vw - 40px);
	}

	&.right {
		@media (max-width: 992px) {
			left: auto;
			right: 20px;
			width: 200px;
		}
	}
}

.service_center {
	// @media (max-width:868px) {
	//   padding-top: 20px;
	// }

	// @media (max-width:768px) {
	//   padding-top: 0px;
	// }

	// @media (max-width:607px) {
	//   padding-top: 20px;
	// }
}

.products-mob-item__row {
	.input-note-desc {
		top: -30px;
	}
}

.input-note {
	&:hover ~ .input-note-desc,
	&:active ~ .input-note-desc {
		opacity: 1;
		z-index: 5;
		display: block;
	}

	&:hover .input-note-desc,
	&:active .input-note-desc {
		opacity: 1;
		z-index: 5;
		width: max-content;
		transform: translateX(-100%);
		display: block;
		height: auto;
	}

	&:hover .input-note-desc.right,
	&:active .input-note-desc.right {
		opacity: 1;
		z-index: 5;
		width: max-content;
		transform: translateX(120%);
		display: block;
	}

	&:hover .input-note-desc.top,
	&:active .input-note-desc.top {
		opacity: 1;
		z-index: 5;
		width: max-content;
		transform: translate(-50%, -55%);
		display: block;
	}

	&:hover .input-note-desc.bottom,
	&:active .input-note-desc.bottom {
		opacity: 1;
		z-index: 5;
		width: max-content;
		transform: translate(-50%, 155%);
		display: block;
	}
}

.input-note-desc {
	&:hover,
	&:active {
		opacity: 1;
		z-index: 5;
		width: max-content;
		display: block;
	}
}

.add-row-inputs {
	font-family: 'Montserrat';
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	border-radius: 10px;
	height: 50px;
	max-width: 340px;
	width: 100%;
	box-sizing: border-box;
	padding: 0 30px;
	color: #fff;
	transition: all 0.3s;
	background: var(--main-color);
	cursor: pointer;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin-bottom: 20px;

	@media (max-width: 567px) {
		max-width: 100%;
	}

	i {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&:hover {
		background: #4e41ab;
	}
}

.mobi-full {
	@media (max-width: 567px) {
		max-width: 100%;
		width: 100%;
	}
}

.input-file {
	width: 100%;
	.file-name {
		display: flex;
		align-items: center;
		gap: 10px;
		font-size: 12px;
		font-weight: 300;
		margin-bottom: 10px;
	}

	input {
		display: none;
	}

	label {
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
		border-radius: 10px;
		border: 1px solid #555;
		cursor: pointer;
		max-width: 240px;
		width: 100%;
		height: 50px;
		gap: 10px;
		color: #333;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		transition: all 0.3s;

		i {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		path {
			transition: all 0.3s;
		}

		&:hover {
			background: #4e41ab;
			border-color: #4e41ab;
			color: #fff;

			path {
				stroke: #fff;
			}
		}
	}

	.remove-img {
		cursor: pointer;

		path {
			transition: all 0.3s;
		}

		&:hover path {
			stroke: #4e41ab;
			stroke-opacity: 1;
		}
	}
}

.textarea-block {
	margin-bottom: 20px;
	position: relative;

	textarea {
		border-radius: 10px;
		border: none;
		height: 120px;
		width: 100%;
		max-width: 100%;
		box-sizing: border-box;
		padding: 20px;
		color: #333;
		font-family: 'Montserrat';
		font-size: 15px;
		font-weight: 400;
		line-height: 17px;
		outline: none;
		background: #ebf1f8;
		resize: none;
		padding-right: 40px;

		&:focus {
			padding-top: 30px;
		}

		@media (max-width: 480px) {
			font-size: 12px;
		}
	}
	.input-placeholder {
		background: #ebf1f8;
	}

	&:focus,
	&.active {
		// padding-top: 15px;
		textarea {
			padding-top: 0;
			// margin-top: 30px;
			padding-top: 30px;

			&:focus {
				// padding-top: 0;
			}
		}

		~ .input-placeholder {
			transition: all 0s ease 0s;
			// top: 12px;
			font-size: 12px;
			font-weight: 400;
			line-height: 14px;
			background: #ebf1f8;
			z-index: 2;
			top: 0;
			padding-top: 12px;
			height: 32px;
		}
	}

	.input-note {
		top: 20px;
		right: 20px;
		transform: translate(0);
	}
}

.terms-div {
	display: flex;
	align-items: flex-start;
	gap: 7px;
	font-size: 10px;
	font-weight: 400;
	line-height: 134.09%;
	color: #7e868e;
	margin-top: 10px;
	max-width: 375px;
	width: 100%;

	a {
		text-decoration: underline;
		color: #7e868e;
		transition: all 0.3s;

		&:hover {
			color: #4e41ab;
		}
	}
}

.terms-button {
	accent-color: var(--main-color);
	cursor: pointer;
}

.block-save {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 20px;
	margin-top: 40px;
}
.block-save-bottom {
	margin-bottom: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.block-save-left {
	max-width: 340px;
	width: 100%;
}

.block-term {
	align-items: flex-start;

	.date-save {
		margin-top: 19px;
	}
}

.radio-block {
	margin-bottom: 30px;

	label {
		display: flex;
		align-items: center;
		gap: 7px;
		color: #222;
		font-size: 18px;
		font-weight: 400;
		line-height: 26px;
		margin-bottom: 15px;
	}

	input {
		accent-color: var(--main-color);
	}
}

.inputs-date {
	display: flex;
	gap: 20px;
}

.react-datepicker__view-calendar-icon input {
	border-radius: 10px !important;
	border: 1px solid #555 !important;
	width: 139px !important;
	height: 50px !important;
	box-sizing: border-box;
	padding: 0 15px !important;
	cursor: pointer;
	color: #222;
	font-family: 'Montserrat';
	font-size: 15px;
	font-weight: 400;
	z-index: 4;
	background: none;
}

.react-datepicker__input-container {
	.react-datepicker__calendar-icon {
		padding: 0 !important;
		right: 15px;
		width: 20px !important;
		height: 20px !important;
		top: 50% !important;
		transform: translateY(-50%) !important;
	}

	position: relative;
	display: flex !important;
	width: 100%;
	flex-direction: row-reverse !important;
	align-items: center !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
	background: var(--main-color) !important;
}

@media (max-width: 480px) {
	.inputs-row {
		flex-wrap: wrap;
		gap: 0;

		.input-block {
			flex: 100%;
		}
	}

	.input-block {
		margin-bottom: 15px;
		border-radius: 6px;
	}

	.block-save {
		flex-direction: column;
		align-items: flex-start;
		margin-top: 10px;

		.btn {
			max-width: 100%;
		}

		.date-save {
			margin-top: 15px;
			font-size: 12px;
			line-height: 16px;
		}
	}

	.input-placeholder {
		font-size: 12px;
		line-height: 14px;
		top: 18px;
		left: 13px;
	}

	.input-block {
		min-height: 50px;

		input {
			min-height: 50px;
			font-size: 12px;
			line-height: 14px;
			padding-left: 13px;

			&.active ~ .input-placeholder,
			&:focus ~ .input-placeholder {
				top: 8px;
				font-size: 10px;
				line-height: 12px;
			}

			&::placeholder {
				font-size: 12px;
				line-height: 14px;
			}
		}
	}

	.block-save .date-save {
		margin-top: 15px;
	}

	.block-save-left {
		max-width: 100%;
	}

	.terms-div {
		align-items: center;
		font-size: 8px;
		line-height: 134.09%;
	}

	.input-file label {
		height: 40px;
		max-width: 100%;
		border-radius: 6px;
		font-size: 13px;
		line-height: 19px;

		svg {
			width: 15px;
			height: 15px;
		}
	}

	.modal__form .textarea-block {
		margin-bottom: 15px;
	}

	.textarea-block textarea {
		padding: 15px;
		border-radius: 6px;
	}

	.textarea-block .input-note {
		top: 15px;
		right: 13px;
	}

	.radio-block {
		label {
			font-size: 14px;
			line-height: 21px;
			align-items: flex-start;

			input {
				top: 5px;
				position: relative;
			}
		}

		margin-bottom: 20px;
	}
}
