.projectcontrolpage {
	width: 100%;
	padding-bottom: 80px;
}

.projectcontrolpage-header {
	padding-top: 60px;
	padding-bottom: 50px;
}

.projectcontrolpage-header-title {
	display: flex;
	justify-content: space-between;

	h1 {
		color: #222;
		font-size: 30px;
		font-weight: 500;
		line-height: 44px;
	}

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		border-radius: 10px;
		background: #fff;
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
		cursor: pointer;
		max-width: 106px;
		width: 100%;
		height: 42px;
		transition: all 0.3s;
		color: rgba(51, 51, 51, 0.7);
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;

		@media (max-width:1200px) {
			width: 106px;
		}

		&:hover {
			background: #4e41ab;
			color: #fff;
		}
	}
}

.projectcontrolpage-column {
	max-width: 380px;
	width: 100%;
}

.projectcontrolpage {

	select,
	option {
		font-family: 'Montserrat';
		color: #222;
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
	}
}

.projectcontrolpage-data {
	select {
		option {
			box-sizing: border-box;
			padding: 5px 15px;
			background: #ebf1f8;

			&:hover {
				background: #fff;
			}
		}

		background: #f8f8fa;
		border: none !important;
	}

	color: #222;
	font-size: 20px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 15px;
	max-width: 160px;
	max-width: 225px;
	width: 100%;

	.settings-select__single-value {
		font-size: 20px !important;
		font-weight: 400 !important;
		line-height: 24px !important;
		color: #222 !important;
	}
}

.projectcontrolpage-projects-header .settings-select__single-value {
	font-size: 17px !important;
	font-weight: 400 !important;
	line-height: 24px !important;
	color: #222 !important;
}

.projectcontrolpage-data {
	.settings-select__value-container {
		padding: 0 !important;
	}

	.settings-select__placeholder {
		font-family: 'Montserrat';
		color: #222;
		font-size: 20px;
		font-weight: 400;
		line-height: 24px;
	}
}

.projectcontrolpage-projects-header .settings-select__placeholder {
	font-family: 'Montserrat';
	color: #222;
	font-size: 17px;
	font-weight: 400;
	line-height: 24px;
}

.projectcontrolpage-data .settings-select__menu,
.projectcontrolpage-projects-header .settings-select__menu {
	padding: 0 !important;
	margin: 0 !important;
	border: none !important;
	background: #ebf1f8 !important;
	box-shadow: none !important;
	border-radius: 10px !important;
	overflow: hidden;
	position: absolute !important;
	transform: translateY(-0);
	z-index: 10 !important;
}

.projectcontrolpage-data .settings-select__menu-list,
.projectcontrolpage-projects-header .settings-select__menu-list {
	max-height: 100% !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.projectcontrolpage-data {

	.settings-select__option--is-focused,
	.settings-select__option--is-selected {
		background: #fff !important;
		color: #333 !important;
	}
}

.projectcontrolpage-projects-header {

	.settings-select__option--is-focused,
	.settings-select__option--is-selected {
		background: #fff !important;
		color: #333 !important;
	}
}

.projectcontrolpage-data .settings-select__option,
.projectcontrolpage-projects-header .settings-select__option {
	cursor: pointer !important;
	font-size: 16px;
	line-height: 18px;
}

.projectcontrolpage select {
	border: none;
	outline: none;
	height: 50px;
	appearance: none;
	cursor: pointer;
}

.projectcontrolpage-projects-header-sort {
	max-width: 380px;
	width: 100%;

	.settings-select__control {
		// max-width: 190px !important;
	}
}

.projectcontrolpage select {
	background-image: linear-gradient(45deg, transparent 50%, black 50%), linear-gradient(135deg, black 50%, transparent 50%);
	background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px);
	background-size: 5px 5px, 5px 5px;
	background-repeat: no-repeat;

	&:active,
	&:focus {
		background-image: linear-gradient(45deg, black 50%, transparent 50%), linear-gradient(135deg, transparent 50%, black 50%);
		background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em;
		background-size: 5px 5px, 5px 5px;
		background-repeat: no-repeat;
		border-color: green;
		outline: 0;
	}
}

.projectcontrolpage-date-selection {
	display: flex;
	justify-content: space-between;

	.btn {
		height: 50px;
		max-width: 263px;
		width: 100%;
		gap: 10px;
	}
}

.projectcontrolpage-projects {
	margin-top: 30px;

	table {
		width: 100%;
		border-collapse: collapse;
	}
}

.projectcontrolpage-projects-header {

	select,
	p {
		color: #333;
		font-size: 17px;
		font-weight: 400;
		line-height: 20px;
	}


}

.projectcontrolpage-projects-body {
	align-items: center;
	gap: 8vw;
	padding: 10px 0;

	td {
		padding: 10px 0;

	}
}

.projectcontrolpage-projects-header {
	select {
		padding: 0% 1%;
		border-radius: 8px;
	}

	border-radius: 8px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.02);
	font-size: 110%;
	height: 50px;

	p {
		color: #333;
		font-size: 17px;
		font-weight: 400;
		line-height: 20px;
	}
}

.projectcontrolpage-projects-body {
	padding: 20px 0;
	border-bottom: 1px solid #b1a9a96e;

	a {
		font-size: 120%;
		text-decoration: none;
		color: var(--main-color);
		font-size: 20px;
		font-weight: 400;
		transition: all 0.3s;
		display: flex;
		align-items: center;
		gap: 5px;
	}
}

.projects-color {
	border-radius: 100%;
	width: 10px;
	height: 10px;
	flex-shrink: 0;
	background: red;
}

.projectcontrolpage-projects-body a {
	&:hover {
		color: #4e41ab;
	}

	width: 20vw;
	padding-left: 1%;
}

.projectcontrolpage-projects-header select {
	width: 20vw;
	padding-left: 1%;
}

.ammount-col {
	width: 15vw;
}

.projectcontrolpage-projects-body {

	.ammount-col,
	.price-col {
		color: #6e6e6f;
		font-size: 16px;
		font-weight: 400;
	}
}

.price-col {
	width: 10vw;
}

.projectcontrolpage-projects-body-buttons {
	margin-right: 1%;
	flex-grow: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;



	.action-button {
		color: #fff;
		background: var(--main-color);
		height: 34px;

		@media (any-hover: hover) {
			&:hover {
				background: #4e41ab;

			}
		}
	}
}

.folder-button,
.action-button {
	border: none;
	outline: none;
	background: transparent;
	cursor: pointer;
	font-family: 'Montserrat';
}

.folder-button {
	display: flex;
	align-items: center;
	gap: 10px;
	color: #6e6e6f;
	width: 116px;
	text-align: center;
	font-size: 20px;
	font-weight: 400;
	line-height: 20px;
	transition: all 0.3s;

	path {
		transition: all 0.3s;
	}

	&:hover {
		color: #4e41ab;

		path {
			stroke: #4e41ab;
		}
	}
}

.action-button {
	border-radius: 5px;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
	border: none;
	cursor: pointer;
	transition: all 0.3s;
	height: 40px;
	width: 116px;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		background: #4e41ab;
		color: #fff;
	}
}

@media (max-width: 1200px) {
	.projectcontrolpage-projects-header {
		gap: 5vw;
	}

	.projectcontrolpage-projects-body {
		gap: 5vw;

		.ammount-col,
		.price-col {
			color: #6e6e6f;
			font-size: 14px;
		}
	}

	.folder-button {
		font-size: 16px;
	}
}

@media (max-width: 820px) {

	.projectcontrolpage-projects-header,
	.projectcontrolpage-projects-body {
		gap: 3vw;
	}
}

@media (max-width: 750px) {
	.projectcontrolpage-date-selection {
		flex-direction: column;
		gap: 10px;
	}
}

@media (max-width: 650px) {
	.projectcontrolpage-date-selection input {
		width: 50%;
		min-width: 0;
		border-radius: 6px !important;
	}

	.projectcontrolpage-data {
		text-align: left;
	}

	.projectcontrolpage-date-selection {
		align-items: center;

		button {
			width: 100%;
			justify-content: center;
		}
	}

	.projectcontrolpage-projects-header p,
	.ammount-col,
	.price-col,
	.folder-button {
		display: none;
	}

	.projectcontrolpage-projects-header select {
		width: 50vw;
	}

	.projectcontrolpage-projects-body {
		a {
			width: 50vw;
		}

		justify-content: space-between;
		padding: 10px 0;
	}

	.action-button {
		font-size: 11px;
		line-height: 20px;
		width: 96px !important;
		min-height: 34px !important;
		border-radius: 5px !important;
		// height: 34px;
	}

	.projectcontrolpage {
		padding-bottom: 60px;
	}

	.projectcontrolpage-header {
		padding-top: 61px;
		padding-bottom: 35px;
	}

	.projectcontrolpage-header-title h1 {
		color: #222;
		font-size: 20px;
		font-weight: 500;
		line-height: 26px;
	}

	.projectcontrolpage-projects-body a {
		font-size: 15px;
		line-height: 20px;
	}

	.projectcontrolpage-projects-body-buttons {
		flex-grow: 0;


		.product-row {
			margin-left: auto;
		}

		.action-button {
			color: #fff;
			background: var(--main-color);
			height: 34px;

			@media (any-hover: hover) {
				&:hover {
					background: #4e41ab;

				}
			}
		}
	}

	.projectcontrolpage-header-title button {
		width: 106px;
		flex: 1 0 106px;
	}

	.projectcontrolpage-date-selection {
		input {
			width: 100% !important;
			height: 40px !important;
		}

		.btn {
			max-width: 100%;
		}

		gap: 20px;
	}

	.inputs-date {
		gap: 15px;
		width: 100%;
	}

	.input-date {
		width: 50%;
	}

	.react-datepicker-wrapper {
		width: 100%;
	}

	.react-datepicker__navigation-icon::before {
		display: none !important;
	}

	.projectcontrolpage-date-selection .btn {
		height: 40px;
		border-radius: 6px;
		gap: 6px;
		font-size: 12px;
		font-weight: 500;
		line-height: 12px;

		svg {
			width: 18px;
			height: 18px;
		}
	}

	.projectcontrolpage-projects-header {
		height: 44px;
		padding-left: 12px;
		border-radius: 6px;

		select {
			height: 100%;
		}
	}

	.projectcontrolpage-data .settings-select__placeholder {
		font-size: 16px !important;
		font-weight: 400 !important;
		line-height: 16px !important;
	}

	.projectcontrolpage-projects-header {
		.settings-select__placeholder {
			font-size: 16px !important;
			font-weight: 400 !important;
			line-height: 16px !important;
		}

		.settings-select__value-container {
			padding-left: 0 !important;
		}
	}

	.projectcontrolpage-data .settings-select__single-value,
	.projectcontrolpage-projects-header .settings-select__single-value {
		font-size: 16px !important;
		font-weight: 400 !important;
		line-height: 16px !important;
	}
}