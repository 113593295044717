.landing-banner {
	margin-top: 58px;
	width: 100%;
}

.landing-banner__block {
	display: flex;
	justify-content: space-between;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	border-radius: 20px;
	background: radial-gradient(
		1414.12% 141.42% at 100% 100%,
		#8a7de7 0%,
		#4e41ab 100%
	);
	height: 551px;
	position: relative;
	width: 100%;
	align-items: center;
}

.landing-banner__info {
	box-sizing: border-box;
	padding-left: 50px;
	max-width: 600px;
	width: 100%;
	max-width: 100%;
}

.landing-banner__info h1 {
	color: #fff;
	font-size: 38px;
	font-weight: 600;
	line-height: 46px;
	text-transform: uppercase;
}
.landing-banner__info h2 {
	color: #fff;
	font-size: 26px;
	font-weight: 600;
	line-height: 38px;
	text-transform: uppercase;

	@media (max-width: 1060px) {
		line-height: 100%;
		font-size: 20px;
	}
	@media (max-width: 480px) {
		font-size: 15px;
	}
}

.landing-banner__info h1 span {
	color: var(--green);
	font-size: 44px;
	font-weight: 700;
	line-height: 50px;
	display: block;

	&.light {
		font-weight: 400;
	}
}

.about-banner .landing-banner__info {
	max-width: 630px;
}

.landing-banner__info p {
	margin-top: 25px;
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
}

.landing-banner__info button {
	border-radius: 10px;
	background: #fff;
	margin-top: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 265px;
	width: 100%;
	height: 52px;
	color: var(--main-color);
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	transition: all 0.3s;
	cursor: pointer;
	outline: none;
	border: none;
	text-decoration: none;
}
.landing-banner__info a {
	// height: 100%;
	// width: 100%;
	text-decoration: none;
	text-align: center;
	// display: flex;
	align-items: center;
	justify-items: center;
}

.landing-banner__info button:hover {
	color: #fff;
	background: var(--green);
}

.landing-banner__img {
	max-width: 1000px;
	position: relative;
	width: 100%;
}

.landing-banner__img img {
	position: relative;
	max-height: 695px;
	width: auto;
	max-width: 100%;
}

.about-banner .landing-banner__img {
	max-width: 900px;
}

.landing-section2 {
	padding-top: 110px;
	padding-bottom: 100px;
	max-width: 100%;
	width: 100%;
}

.landing-section {
	padding: 0 !important;
	margin: 50px 0;

	.strong {
		font-weight: 600;
		@media (max-width: 1060px) {
			font-size: 14px;
			margin-bottom: 10px;
			margin-top: 5px;
			line-height: 16px;
		}
		@media (max-width: 480px) {
			font-size: 10px;
		}
	}

	.tariff {
		padding: 25px;
		@media (max-width: 480px) {
			font-size: 10px;
			width: calc(50% - 10px);
			padding: 13px;
		}
		.tariff__name {
			width: 100%;
			padding-bottom: 15px;
			border-bottom: 1px solid #ebf1f8;

			@media (max-width: 480px) {
				font-size: 10px;
			}
		}
		.tariff__footer {
			position: static;
			padding: 0;
			margin-top: 30px;
			@media (max-width: 480px) {
				margin-top: 10px;
			}

			.tariff__btn button {
				@media (max-width: 480px) {
					font-size: 10px;
					height: 32px;
				}
			}
		}

		.tariff-sale {
			@media (max-width: 480px) {
				font-size: 7px;
				padding-top: 0;
				padding-bottom: 0;
			}
		}

		.tariff__price {
			margin-top: 0;
			padding-top: 15px;

			border-bottom: none;
			@media (max-width: 480px) {
				span {
					font-size: 18px;
				}
			}
		}
	}

	.landing-list {
		font-size: 18px;
		font-weight: 400;
		color: #222222;
		line-height: 24px;
		display: flex;
		flex-direction: column;
		gap: 4px;
		list-style: none;
		@media (max-width: 1060px) {
			font-size: 14px;
			line-height: 22px;
		}
		@media (max-width: 480px) {
			line-height: 16px;
			font-size: 10px;
		}

		li {
			position: relative;
			padding-left: 13px;
			&::after {
				content: '';
				position: absolute;
				width: 9px;
				top: 7px;

				left: 0;
				height: 9px;
				border-radius: 100%;
				background: var(--main-color);
			}
		}
	}

	&.landing-section2 {
		@media (max-width: 768px) {
			.landing-section4__row {
				flex-direction: column-reverse;

				.landing-section4__img {
					height: 350px;
					img {
						height: 100%;
					}
				}
			}
		}
	}

	@media (max-width: 1440px) {
		padding: 0;
	}
	@media (max-width: 1060px) {
		padding: 0;
	}
}

.landing-title {
	margin-bottom: 50px;
}

.landing-title h2 {
	color: var(--main-color);
	font-size: 32px;
	font-weight: 700;
	line-height: 44px;
	text-transform: uppercase;
}

.landing-section2__list {
	display: flex;
	flex-wrap: wrap;
	gap: 50px 30px;
	justify-content: space-between;

	&.tariff__list {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		@media (max-width: 1024px) {
			gap: 20px;

			.tariff {
				padding: 20px;
			}
			.tariff__footer {
				margin-top: 25px;
			}
			.tariff__price .price {
				font-size: 24px;
			}

			.tariff__btn button {
				height: 46px;
				font-size: 14px;
			}
		}

		@media (max-width: 768px) {
			grid-template-columns: 1fr;
		}
		.tariff {
			width: 100%;
		}
	}

	&.landing-vpn-list {
		@media (max-width: 480px) {
			gap: 50px;
		}
		.landing-section2-item {
			&:nth-child(4),
			&:nth-child(5) {
				width: calc(50% - 20px);

				@media (max-width: 1060px) {
					width: calc(50% - 14px);
				}
				@media (max-width: 480px) {
					width: 100%;
				}
			}
		}
		// display: grid;
		// grid-template-columns: repeat(auto-fill, minmax(427px, 1fr));

		// .landing-section2-item {
		// 	width: auto;
		// }
	}
}

.landing-section2-item {
	position: relative;
	width: calc(33.333% - 20px);
	box-sizing: border-box;
	padding: 95px 25px 40px 25px;
	border-radius: 18px;
	.landing-list {
		font-size: 16px;
	}

	@media (any-hover: hover) {
		&:hover {
			.landing-list {
				color: #fff;
				li {
					&::after {
						content: '';
						background: #fff;
					}
				}
			}
		}
	}
}

.landing-section-partner_grid-item__text {
	~ span {
		font-weight: 400;
	}
}

.landing-section-partner_grid-item__img-absolute {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	object-fit: cover;
	img {
		height: 100%;
	}

	@media (max-width: 550px) {
		top: 50px;
		bottom: auto;
		transform: rotate(-20deg);
		img {
			width: 200px;
		}
	}
	@media (max-width: 380px) {
		top: 60px;
	}
}

.landing-section2-item::before {
	content: '';
	background: #fff;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 18px;
	transition: all 0.3s;
	opacity: 1;
	border: 1px solid #f2f6fa;
}

.landing-section2-item::after {
	content: '';
	background: #fff;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 18px;
	transition: all 0.3s;
	opacity: 0;
	background: radial-gradient(
		1414.12% 141.42% at 100% 100%,
		#8a7de7 0%,
		#4e41ab 100%
	);
	filter: drop-shadow(0px 2px 10px rgba(12, 11, 11, 0.05));
}

.landing-section2-item:hover::before {
	opacity: 0;
}

.landing-section2-item:hover::after {
	opacity: 1;
}

.landing-section2-item__icon {
	position: absolute;
	width: 116px;
	height: 116px;
	background: #f9f9fb;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	top: -50px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 3;
}

.landing-section2-item__icon span {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 84px;
	height: 84px;
	border-radius: 100%;
	position: relative;
	filter: drop-shadow(0px 2px 10px rgba(12, 11, 11, 0.05));
}

.landing-section2-item__icon span svg {
	position: relative;
	transition: all 0.3s;
	z-index: 3;
}

.landing-section2-item__icon span path,
.landing-section2-item__icon span circle {
	transition: all 0.3s;
}

.landing-section2-item:hover .landing-section2-item__icon span path,
.landing-section2-item:hover .landing-section2-item__icon span circle {
	stroke: var(--main-color);
}

.landing-section2-item__icon span::before {
	content: '';
	width: 100%;
	height: 100%;
	border-radius: 100%;
	filter: drop-shadow(0px 2px 10px rgba(12, 11, 11, 0.05));
	background: radial-gradient(
		1414.12% 141.42% at 100% 100%,
		#8a7de7 0%,
		#4e41ab 100%
	);
	position: absolute;
	left: 0;
	top: 0;
	transition: all 0.3s;
}

.landing-section2-item__icon span::after {
	content: '';
	width: 100%;
	height: 100%;
	border-radius: 100%;
	filter: drop-shadow(0px 2px 10px rgba(12, 11, 11, 0.05));
	background: #fff;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	transition: all 0.3s;
}

.landing-section2-item:hover .landing-section2-item__icon span::before {
	opacity: 0;
}

.landing-section2-item:hover .landing-section2-item__icon span::after {
	opacity: 1;
}

.landing-section2-item__title {
	position: relative;
	color: #333;
	font-size: 19px;
	font-weight: 600;
	line-height: 31px;
	transition: all 0.3s;
	z-index: 3;
}

.landing-section2-item:hover .landing-section2-item__title {
	color: #fff;
}

.landing-section2-item__text {
	position: relative;
	margin-top: 20px;
	color: #333;
	font-size: 16px;
	font-weight: 400;
	line-height: 26px;
	transition: all 0.3s;
	z-index: 3;
}

.landing-section2-item:hover .landing-section2-item__text {
	color: #fff;
}

.landing-section3 {
	max-width: 100%;
	width: 100%;
}

.landing-section3__list {
	display: flex;
	gap: 100px 30px;
	flex-wrap: wrap;

	&.landing-vpn-list {
		display: grid;
		grid-template-columns: 1fr 1fr;

		.landing-section3-item {
			width: 100%;
			text-transform: uppercase;
			font-weight: 600;

			.landing-section3-item__text {
				font-weight: 600;
			}

			@media (max-width: 480px) {
				height: 129px;
			}

			&:last-child {
				padding: 0;
				grid-column: span 2;
				justify-content: space-between;
				gap: 10px;
				@media (max-width: 760px) {
					padding-top: 20px;
				}
				@media (max-width: 480px) {
					overflow: hidden;
				}

				.landing-section3-item__img {
					height: 143px;
					width: calc(33% - 20px);

					@media (max-width: 1060px) {
						height: 117px;
					}

					@media (max-width: 760px) {
						height: 62px;
						margin-top: 0;
						width: 100%;
						&:first-child {
							display: none;
						}
					}
					@media (max-width: 480px) {
						position: static;
					}
					img {
						position: absolute;
						height: 100%;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 176px;
						object-fit: contain;

						@media (max-width: 1060px) {
							height: 123px;
						}
						@media (max-width: 760px) {
							height: 60px;
						}
						@media (max-width: 480px) {
							height: 93px;
							bottom: -21%;
						}
					}
				}
			}
		}
	}

	&.platform__list {
		flex-wrap: nowrap;
		overflow: auto;
		@media (max-width: 480px) {
			padding-bottom: 20px;
		}
	}
}

.platform-item {
	max-width: 244px;
	width: 100%;
	transition: all 0.3s ease 0s;
	@media (any-hover: hover) {
		&:hover {
			transform: translateY(-20px);
			.platform-item--img {
				svg,
				path {
					fill: var(--main-color);
				}
			}
		}
	}

	.platform-item--img {
		padding: 35px;
		border-radius: 20px;
		border: 1px solid #f6f6f6;
		box-shadow: 0px 2px 10px 0px #0c0b0b0d;
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: 1060px) {
			padding: 25px;
			svg {
				height: 91px;
				width: 91px;
			}
		}

		@media (max-width: 760px) {
			padding: 17px;
			svg {
				height: 62px;
				width: 62px;
			}
		}

		svg {
			width: 125px;
			height: 125px;
		}
	}

	.platform-item--title {
		color: #333333;
		text-align: center;
		font-size: 22px;
		font-weight: 600;

		@media (max-width: 1060px) {
			font-size: 18px;
		}

		@media (max-width: 760px) {
			font-size: 12px;
		}
	}
}

.landing-section-partner {
	.landing-section-partner_grid {
		@media (max-width: 1440px) {
			gap: 15px;
		}
		@media (max-width: 1060px) {
			gap: 10px;
		}
		@media (max-width: 768px) {
			margin-top: -35px;
		}
	}
}

.landing-section-partner_grid {
	display: grid;
	gap: 30px;
	grid-template-columns: repeat(3, 1fr); /* 3 колонки */
	grid-template-rows: auto;

	.landing-section-partner_grid-item {
		width: 100%;
		background-color: #fff;
		padding: 30px;
		font-size: 20px;
		font-weight: 600;
		display: flex;
		gap: 15px;
		border-radius: 10px;
		box-shadow: 0px 2px 10px 0px #0c0b0b0d;
		position: relative;
		overflow: hidden;
		line-height: 146%;

		.landing-section-partner_grid-item__img {
			background: radial-gradient(
				100% 1411.12% at 100% 100%,
				#8a7de7 0%,
				#4e41ab 100%
			);
			border-radius: 100%;
			height: 25px;
			flex: 0 0 25px;
			color: #fff;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 3px;
		}
		&:nth-child(4) {
			grid-column: 1 / -1;
		}

		@media (max-width: 1060px) {
			padding: 25px;
			font-size: 15px;
			gap: 5px;

			.landing-section-partner_grid-item__img {
				height: 20px;
				flex: 0 0 20px;
				font-size: 12px;
			}
		}

		@media (max-width: 768px) {
			padding: 20px;
			font-size: 13px;
			.landing-section-partner_grid-item__img {
				height: 16px;
				flex: 0 0 16px;
				font-size: 9px;
			}
		}
	}

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
}

.landing-section3-item {
	width: calc(50% - 15px);
	position: relative;
	display: flex;
	align-items: center;
	gap: 30px;
	max-height: 175px;
	height: 100%;
	box-sizing: border-box;
	padding-left: 20px;
	padding-right: 30px;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	border-radius: 18px;
	border: 1px solid #f6f6f6;
}

.landing-section3-item::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: #fff;
	transition: all 0.3s;
	opacity: 1;
	border-radius: 18px;
}

.landing-section3-item::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: radial-gradient(
		1414.12% 141.42% at 100% 100%,
		#8a7de7 0%,
		#4e41ab 100%
	);
	transition: all 0.3s;
	opacity: 0;
	border-radius: 18px;
}

.landing-section3-item:hover::before {
	opacity: 0;
}

.landing-section3-item:hover::after {
	opacity: 1;
}

.landing-section3-item__img {
	width: 282px;
	height: 282px;
	position: relative;
	z-index: 3;
}

.landing-section3-item__img img {
	height: 100%;
	width: auto;
}

.landing-support.landing-section {
	@media (max-width: 1060px) {
		padding: 0;
	}
	@media (max-width: 768px) {
		height: 560px;

		.landing-banner__img {
			position: relative;
			bottom: -20px;
		}

		.landing-banner__info button {
			max-width: 100%;
		}
	}

	@media (max-width: 480px) {
	}
}

.landing-section-partner {
	.landing-section3-item__img {
		width: 214px;
		height: 214px;
		position: relative;
		z-index: 3;

		@media (max-width: 1440px) {
			width: 195px;
		}

		@media (max-width: 1060px) {
			height: 90px;
			width: 90px;
		}
	}

	.landing-section3-item {
		max-height: 146px;

		@media (max-width: 1060px) {
			max-height: max-content;
		}
	}
}

.landing-section3-item__text {
	position: relative;
	z-index: 3;
	color: #333333;
	font-size: 20px;
	font-weight: 500;
	line-height: 31px;
	transition: all 0.3s;
}

.landing-section3-item:hover .landing-section3-item__text {
	color: #fff;
}

.landing-section4 {
	width: 100%;
	max-width: 100%;
	padding-top: 160px;
	padding-bottom: 100px;
}

.landing-section4__row {
	display: flex;
	gap: 30px;
	align-items: center;
}

.landing-section4__info {
	max-width: 748px;
	width: 100%;
	flex: 1 1 100%;
}

.landing-section4__info h2 {
	color: var(--main-color);
	font-size: 32px;
	font-weight: 700;
	line-height: 44px;
	text-transform: uppercase;
	margin-bottom: 50px;
}

.landing-section4__info p {
	margin-bottom: 20px;
	color: #333;
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
}

.landing-section4__info .btn {
	max-width: 350px;
	height: 68px;
	font-size: 18px;
	font-weight: 500;
	line-height: 20px;
	margin-top: 50px;
}

.landing-section4__img {
	// max-width: 846px;
	max-width: 541px;
	width: 100%;
}

.landing-section4__img img {
	max-width: 100%;
	display: block;
	margin: 0 auto;
}

.banner-support {
	width: 100%;
	max-width: 100%;
	padding-bottom: 80px;

	&.banner-support--reverse {
		padding: 0;

		.banner-support__block {
			background: #fff;
			overflow: hidden;
			margin-top: 30px;
		}

		h2,
		p {
			color: #222222;
		}
		p {
			font-weight: 400;
		}
		a {
			transition: all 0.3s ease 0s;
			background: var(--main-color);
			color: #fff;
			display: flex;
			align-items: center;
			width: 100%;
			flex: 1;
			justify-content: center;
		}
	}

	@media (max-width: 480px) {
		.container {
			max-width: 100%;
			padding: 0;
		}
	}
}

.banner-support__block {
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	background: radial-gradient(
		1414.12% 141.42% at 100% 100%,
		#8a7de7 0%,
		#4e41ab 100%
	);
	border-radius: 17px;
	box-sizing: border-box;
	padding: 80px 30px;
	text-align: center;
	position: relative;

	.banner-support__free {
		font-size: 13px;
		color: var(--secondary-text);
		margin-top: 14px;
		text-align: center;
		position: relative;
		z-index: 5;
		span {
			color: var(--green);
		}
	}
}

.banner-support h2 {
	margin-bottom: 10px;
	color: #fff;
	font-size: 34px;
	font-weight: 700;
	line-height: 40px;
	text-transform: uppercase;
	position: relative;
}

.banner-support h3 {
	margin-top: 20px;
	color: #fff;
	font-size: 22px;
	font-weight: 600;
	line-height: 30px;
	text-transform: uppercase;
	position: relative;
}

.banner-support p {
	margin-top: 7px;
	color: #fff;
	font-size: 20px;
	font-weight: 500;
	line-height: 28px;
	position: relative;
}

.banner-support__btn {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	position: relative;
}

.banner-support__btn a {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none !important;
	border-radius: 10px;
	background: #fff;
	max-width: 349px;
	width: 100%;
	height: 66px;
	color: var(--main-color);
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	transition: all 0.3s;
}

.banner-support__btn a:hover {
	background: #4e41ab;
	color: #fff;
}

.blob {
	position: absolute;
}

.blob1 {
	left: 0;
	bottom: -50px;
}

.blob2 {
	right: 0;
	top: -35px;
}

.blob3 {
	right: 0;
	bottom: -35px;
}

.landing-section3__link {
	display: flex;
	justify-content: flex-end;
	margin-top: 50px;
}

.landing-section3__link a {
	display: flex;
	align-items: center;
	gap: 7px;
	text-decoration: none;
	color: var(--main-color);
	transition: all 0.3s;
}

.landing-section3__link a path {
	transition: all 0.3s;
}

.landing-section3__link a:hover {
	color: #4e41ab;
}

.landing-section3__link a:hover path {
	stroke: #4e41ab;
}

@media (max-width: 1440px) {
	.landing-banner {
		margin-top: 70px;
	}

	.landing-banner__img {
		max-width: 770px;
	}

	.landing-banner__info {
		padding-left: 40px;
	}

	.landing-banner__info h1 {
		font-size: 34px;
		line-height: 43px;
	}

	.landing-banner__info h1 span {
		font-size: 39px;
		line-height: 45px;
	}

	.landing-banner__block {
		height: 470px;
	}

	.landing-section2 {
		padding-top: 80px;
		padding-bottom: 100px;
	}

	.landing-section {
		padding: 0;
	}
	.landing-title h2 {
		font-size: 34px;
		line-height: 42px;
	}

	.landing-section2-item__title {
		font-size: 20px;
		line-height: 26px;
	}

	.landing-section2-item__text {
		font-size: 16px;
		line-height: 26px;
	}

	.landing-section2__list {
		gap: 30px;
	}

	.landing-section2-item {
		width: calc(33.333% - 20px);
		padding: 90px 20px 30px 20px;
	}

	.landing-section3-item__text {
		font-size: 20px;
		line-height: 28px;
	}

	.landing-section3-item__img {
		width: 245px;
		height: 245px;
	}

	.landing-section3-item {
		height: 153px;
		width: calc(50% - 15px);
	}

	.landing-section3__list {
		gap: 90px 30px;
	}

	.landing-section4__info h2 {
		font-size: 34px;
		line-height: 42px;
	}
}

@media (max-width: 1060px) {
	.blob {
		width: 40%;
	}

	.blob1 {
		left: -75px;
		bottom: 0;
	}

	.blob2 {
		right: 30px;
		top: -30px;
	}

	.blob3 {
		right: 30px;
		bottom: -40px;
	}

	.blob img {
		max-width: 100%;
	}

	.banner-support h2 {
		font-size: 24px;
		line-height: 30px;
	}

	.banner-support h3 {
		font-size: 16px;
		line-height: 24px;
	}

	.banner-support p {
		font-size: 14px;
		line-height: 20px;
	}

	.banner-support p {
		font-size: 14px;
		line-height: 20px;
		max-width: 455px;
		width: 100%;
		margin: 0 auto;
		margin-top: 5px;
	}

	.banner-support__btn a {
		max-width: 320px;
		height: 50px;
	}

	.banner-support__block {
		padding: 55px 30px;
	}

	.banner-support {
		padding: 0px 20px 80px 20px;
	}

	.landing-banner {
		margin-top: 50px;
	}

	.landing-banner__img {
		max-width: 560px;
	}

	.landing-banner__info {
		padding-left: 30px;
	}

	.landing-banner__info h1 {
		font-size: 24px;
		line-height: 33px;
	}

	.landing-banner__info h1 span {
		font-size: 29px;
		line-height: 35px;
	}

	.landing-banner__block {
		height: 450px;
	}

	.landing-banner__info p {
		font-size: 14px;
		line-height: 22px;
		margin-top: 15px;
	}

	.landing-banner__info p br {
		display: none;
	}

	.landing-title h2 {
		font-size: 26px;
		line-height: 34px;
	}

	.landing-section2 {
		padding: 70px 0;
	}

	.landing-section {
		padding: 0;
		margin: 25px 0;
	}

	.landing-title {
		margin-bottom: 65px;
	}

	.landing-section2-item__title {
		font-size: 14px;
		line-height: 20px;
	}

	.landing-section2-item__text {
		margin-top: 15px;
		font-size: 12px;
		line-height: 20px;
	}

	.landing-section2__list {
		gap: 20px;
	}

	.landing-section2-item {
		width: calc(33.333% - 14px);
		padding: 70px 20px 20px 20px;
	}

	.landing-section2-item__icon {
		width: 90px;
		height: 90px;
	}

	.landing-section2-item__icon span {
		width: 64px;
		height: 64px;
	}

	.landing-section2-item__icon svg {
		width: 34px;
		height: 34px;
	}

	.landing-section3__list {
		gap: 20px;
	}

	.landing-section3-item {
		height: 113px;
		width: calc(50% - 10px);
		padding: 0 15px;
		gap: 20px;
	}

	.landing-section3-item__img {
		width: 175px;
		height: 175px;
	}

	.landing-section3-item__text {
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
	}

	.landing-section4 {
		padding-top: 115px;
		padding-bottom: 0;
	}

	.landing-section4__row {
		gap: 20px;
	}

	.landing-section4__info {
		max-width: 390px;
		width: 100%;
	}

	.landing-section4__info h2 {
		font-size: 26px;
		line-height: 34px;
		margin-bottom: 35px;
	}

	.landing-section4__info p {
		margin-bottom: 15px;
		font-size: 14px;
		line-height: 22px;
	}

	.landing-section4__info .btn {
		margin-top: 35px;
		height: 50px;
		font-size: 14px;
		max-width: 265px;
	}

	.landing-support {
		padding: 0;
		padding-bottom: 50px;
	}
}

@media (max-width: 768px) {
	.landing-banner {
		padding: 0;
		margin: 0;
	}

	.landing-banner .container {
		padding: 0;
	}

	.landing-banner__block {
		border-radius: 0;
		height: auto;
		padding: 30px 20px;
	}

	.landing-banner__info {
		padding-left: 0;
	}

	.landing-section3-item {
		flex-direction: column;
		height: auto;
		max-height: 100%;
		padding: 0 10px 20px 10px;
		gap: 10px;
	}

	.landing-section3-item__img {
		height: 114px;
		width: 114px;
		position: relative;
		margin-top: -50px;
	}

	.landing-section4 {
		padding-top: 70px;
	}

	.landing-section4__row {
		gap: 25px;
		flex-direction: column;
	}

	.landing-section4__info {
		max-width: 100%;
		width: 100%;
	}

	.landing-section4__img {
		max-width: 100%;
	}

	.landing-section4__info .btn {
		max-width: 100%;
	}
}

@media (max-width: 480px) {
	.banner-support {
		padding: 0px 0px 60px 0px;
	}

	.blob1 {
		left: 0;
		bottom: 0;
	}

	.blob2 {
		right: 0;
		top: 0;
	}

	.blob3 {
		right: 0;
		bottom: 0px;
	}

	.banner-support__block {
		padding: 35px 20px;
		margin-top: 50px;
		border-radius: 0;
	}

	.banner-support h2 {
		font-size: 18px;
		line-height: 26px;
	}

	.banner-support h3 {
		font-size: 13px;
		line-height: 24px;
		margin-top: 15px;
	}

	.banner-support p {
		max-width: 260px;
		font-size: 10px;
		line-height: 16px;
	}

	.banner-support__btn a {
		max-width: 100%;
		height: 50px;
	}

	.landing-banner__info p {
		font-size: 10px;
		font-weight: 500;
		line-height: 18px;
	}

	.landing-banner__block {
		flex-direction: column;
		padding: 30px 20px 15px 20px;
	}

	.landing-banner__info button {
		margin-top: 25px;
		max-width: 180px;
		height: 40px;
		font-size: 10px;
		line-height: 14px;
	}

	.landing-banner__info h1 {
		font-size: 20px;
		line-height: 27px;
		margin-bottom: 10px;
	}

	.landing-banner__info h1 span {
		font-size: 22px;
		line-height: 28px;
	}

	.landing-section2 {
		padding: 50px 0;
	}

	.landing-title {
		margin-bottom: 60px;
	}

	.landing-title h2 {
		font-size: 18px;
		line-height: 26px;
		font-weight: 600;
	}

	.landing-section2-item {
		width: 100%;
		padding: 55px 20px 35px 20px;
		border-radius: 10px;
	}

	.landing-section2-item__text {
		font-size: 10px;
		line-height: 16px;
	}

	.landing-section3__list {
		gap: 60px 20px;
	}

	.landing-section3-item::after,
	.landing-section3-item::before {
		border-radius: 10px;
	}

	.landing-section3-item__text {
		font-size: 10px;
		line-height: 16px;
	}

	.landing-section4 {
		padding-top: 50px;
	}

	.landing-section4__info h2 {
		margin-bottom: 15px;
		font-size: 18px;
		line-height: 26px;
		font-weight: 600;
	}

	.landing-section4__info p {
		font-size: 10px;
		line-height: 16px;
		margin-bottom: 10px;
	}

	.landing-section4__info .btn {
		height: 40px;
		font-size: 10px;
		line-height: 14px;
		margin-top: 15px;
	}

	.landing-support .container {
		padding: 0;
	}

	.landing-support h2 br {
		display: none;
	}

	.landing-support .banner-support__btn a {
		max-width: 180px;
		height: 40px;
		border-radius: 6px;
		font-size: 10px;
		line-height: 14px;
	}

	.banner-support__btn {
		margin-top: 20px;
	}
}
