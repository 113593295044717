.wrapper {
	position: relative;
	color: #fff;
	background-color: #222222;
	font-weight: 400;
	width: 100%;
	padding: 21px;
	text-align: center;

	a {
		color: var(--main-color);
	}

	.close {
		position: absolute;
		right: 30px;
		top: 50%;
		cursor: pointer;
		transform: translateY(-50%);
	}

	@media (max-width: 480px) {
		font-size: 10px;
		padding: 13px 43px;

		.close {
			position: absolute;
			right: 7px;
			top: 50%;
			cursor: pointer;
		}
	}
}
