.requests-suppliers {
	max-width: 922px;
	width: 100%;

	h1 {
		color: var(--main-color);
		font-size: 26px;
		font-weight: 500;
		line-height: 32px;
	}
}

.requests-suppliers__list {
	margin-top: 30px;
}

.request-supplier {
	margin-bottom: 10px;
	border-radius: 13px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
	box-sizing: border-box;
	padding: 20px;

	&__status {
		max-width: 300px;
		margin-top: 10px;
	}
}

.request-supplier__theme {
	display: flex;
	justify-content: space-between;
	align-items: center;

	p {
		color: #333;
		font-size: 20px;
		font-weight: 500;
		line-height: 28px;
	}

	button {
		background: none;
		border: none;
		outline: none;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		path {
			transition: all 0.3s;
		}

		&:hover path {
			stroke: #4e41ab;
		}
	}
}

.request-supplier__info {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.request-supplier__partner {
	display: flex;
	align-items: center;
	gap: 7px;
	color: #333;
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;

	img {
		height: 50px;
	}
}

.request-supplier__date {
	color: #333;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.request-supplier__note {
	margin-top: 30px;

	input {
		height: 60px;
	}

	.input-block {
		background: #f9f9fb;
		margin-bottom: 0;
	}

	// input:focus {
	//   padding-top: 0;
	// }

	.inputs-row {
		gap: 10px;
	}

	.btn {
		height: 60px;
		max-width: 235px;
		width: 100%;
	}
}

.btn-archive {
	background: #949494;
}

.request-supplier__archive-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 100px;
	margin-bottom: 30px;

	&.active svg {
		transform: rotate(180deg);
	}

	button {
		max-width: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		background: none;
		cursor: pointer;
		outline: none;
		border-radius: 10px;
		border: 1px solid #555;
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
		height: 50px;
		color: #333;
		font-family: 'Montserrat';
		font-size: 17px;
		font-weight: 500;
		line-height: 24px;
		transition: all 0.3s;

		path {
			transition: all 0.3s;
		}

		&:hover {
			background: #4e41ab;
			color: #fff;
			border-color: #4e41ab;

			path {
				stroke: #fff;
			}
		}
	}
}

.requests-suppliers .modal-remove .modal__window {
	max-width: 580px;
}

@media (max-width: 480px) {
	.requests-suppliers h1 {
		font-size: 17px;
		line-height: 23px;
	}

	.request-supplier {
		padding: 15px;
	}

	.request-supplier__theme p {
		font-size: 12px;
		font-weight: 500;
	}

	.request-supplier__info {
		margin-top: 10px;
		flex-direction: column;
		align-items: flex-start;
	}

	.request-supplier__partner {
		font-size: 11px;
		line-height: 17px;

		svg,
		img {
			width: 23px;
			height: 14px;
		}
	}

	.request-supplier__date {
		font-size: 10px;
		font-weight: 400;
		line-height: 16px;
		margin-top: 10px;
	}

	.request-supplier__note {
		margin-top: 15px;

		.btn {
			height: 40px;
			max-width: 100%;
			font-size: 11px;
			line-height: 16px;
		}
	}

	.request-supplier__archive-btn {
		margin-top: 60px;

		button {
			border-radius: 6px;
			height: 40px;
			font-size: 13px;
			line-height: 19px;
		}
	}
}
