/* .projectpage {
    flex-grow: 1;
    width: 100%;
    padding: 0 6% 6%;
    display: flex;
    flex-direction: column;
} */

.projectpage {
	padding-bottom: 80px;
}

.projectpage-header {
	padding-top: 60px;
	padding-bottom: 70px;
}

.projectpage-header-title {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	h1 {
		color: #222;
		font-size: 30px;
		font-weight: 500;
		line-height: 44px;
	}
}

.projectpage-header-title-buttons {
	display: flex;
	gap: 20px;

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		border-radius: 10px;
		background: #fff;
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
		cursor: pointer;
		width: 126px;
		height: 42px;
		transition: all 0.3s;
		color: rgba(51, 51, 51, 0.7);
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;

		&:hover {
			background: #4e41ab;
			color: #fff;
		}
	}

	.back-btn {
		width: 106px;

		@media (max-width: 768px) {
			flex-shrink: 0;
		}
	}

	.excel-button {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		font-family: 'Montserrat';
		height: 42px;
		width: 215px;
		gap: 7px;
		color: #333;
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		transition: all 0.3s;
		background: none;
		border-radius: 5px;
		border: 1px solid #555;

		path {
			transition: all 0.3s;
		}

		&:hover {
			color: #fff;

			path {
				stroke: #fff;
			}
		}
	}
}

.projectpage-search-panel {
	display: flex;
	align-items: center;
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	height: 68px;
	gap: 15px;

	button,
	input {
		border: none;
		outline: none;
	}
}

.projectpage-search-color {
	width: 30px;
	height: 30px;
	border-radius: 100%;
	flex-shrink: 0;

	@media (max-width: 567px) {
		width: 20px;
		height: 20px;
	}
}

.projectpage-search-panel-settings {
	height: 100%;
	width: 156px;
	display: flex;
	align-items: center;
	background: var(--main-color);
	border-radius: 10px;
	overflow: hidden;

	button {
		background: var(--main-color);
		width: 50%;
		height: 100%;
		position: relative;
		transition: all 0.3s;
		display: flex;
		align-items: center;
		justify-content: center;

		&:first-child::before {
			content: '';
			width: 1px;
			height: 48px;
			background: #b1a8f5;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover {
			background: #4e41ab;
		}
	}
}

.projectpage-search-panel-palette {
	height: 100%;
	position: relative;

	button {
		width: 73px;
		height: 100%;
		flex-shrink: 0;
		border-radius: 10px;
		background: var(--main-color);
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
		cursor: pointer;
		transition: all 0.3s;

		&:hover,
		&.active {
			background: #4e41ab;
		}
	}
}

.projectpage-search-panel-paint {
	background: var(--main-color);
	width: 75px;
	border-radius: 8px;
}

.projectpage-search-panel-comment {
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;
	flex: 1;

	form {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
	}

	&.active {
		background-color: white;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		z-index: 9999;

		button {
			background: white;
			color: var(--main-color);

			&:hover path {
				stroke: #fff;
			}
		}
	}

	input,
	button {
		height: 100%;
	}

	input {
		padding-left: 30px;
		flex-grow: 1;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		font-family: 'Montserrat';
		color: #222;
		font-size: 15px;
		font-weight: 400;
		box-sizing: border-box;

		&::placeholder {
			font-family: 'Montserrat';
			color: #222;
			font-size: 15px;
			font-weight: 400;
		}
	}

	button {
		background: var(--main-color);
		width: 75px;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		transition: all 0.3s;

		&:hover {
			background: #4e41ab;
		}
	}
}

.comments-field {
	display: none;

	&.active {
		display: flex;
		flex-direction: column;
		gap: 15px;
		position: absolute;
		top: 100%;
		width: 100%;
		padding: 0% 2%;
		background-color: white;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
	}
}

.comments-field-div {
	display: flex;
	align-items: center;
	gap: 20px;
}

.comments-field-user {
	white-space: nowrap;
	// width: 25%;
}

.comments-field-text {
	// width: 75%;
}

.comments-field-user p {
	background: var(--main-color);
	color: white;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	max-width: 220px;
	width: auto;
	box-sizing: border-box;
	padding: 5px 10px;
	display: inline-block;

	@media (max-width: 650px) {
		font-size: 10px;
	}
}

.projectpage-table {
	width: 100%;

	table {
		background: #f8f8fa;
		border-collapse: separate;
		border-spacing: 0 3px;
		margin: 0;
		padding: 0;
		width: 100%;
		table-layout: fixed;
		padding: 0 0px;
		position: relative;
		margin: 0px;

		/* overflow-x: auto;
      background: #F8F8FA;
      border-collapse: separate;
      border-spacing: 0 3px;
      margin: 0;
      padding: 0;
      width: 100%;
      table-layout: fixed; */
	}

	&::-webkit-scrollbar {
		height: 22px;
	}

	&::-webkit-scrollbar-track {
		background: #dacdff80;
		border-radius: 20px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--main-color);
		border-radius: 20px;
		border: 3px solid #dacdff;
	}

	scrollbar-width: thin;
	scrollbar-color: var(--main-color) #ebeaed;

	scrollbar-track-color: #ebeaed;
	scrollbar-thumb-color: var(--main-color);

	table {
		thead tr {
			height: 45px;
			border-radius: 5px;
			border: 1px solid #eee;
			background: #f2f2f2;
			box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
			font-size: 12px;

			th {
				font-weight: normal;
			}
		}

		tbody tr {
			font-size: 14px;
			background: white;
			box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
			transition: all 0.3s;
		}

		thead tr th,
		tbody tr td {
			border-top: 1px solid #e7e7e7;
			border-bottom: 1px solid #e7e7e7;
		}

		thead tr th:first-of-type,
		tbody tr td:first-of-type {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
			border-left: 1px solid #e7e7e7;
		}

		thead tr th:last-of-type,
		tbody tr td:last-of-type {
			border-bottom-right-radius: 10px;
			border-top-right-radius: 10px;
			border-right: 1px solid #e7e7e7;
		}

		tr {
			background-color: #f8f8f8;
			border: 1px solid #ddd;
		}

		tbody tr {
			height: 50px;
		}

		th,
		td {
			padding: 0.925em 0.125em;
			text-align: left;
		}
	}

	.final-price {
		background: inherit;
		border: none;
		box-shadow: none;
		td {
			border: none !important;
		}
	}
}

.trash-col {
	width: 50px;
	padding-left: 0.7em;
	padding-right: 0.9em;

	button {
		background: transparent;
		border: none;
		outline: none;
		cursor: pointer;

		path {
			transition: all 0.3s;
		}

		&:hover path {
			stroke: #4e41ab;
		}
	}
}

.projectpage-table .product-ammount-col {
	width: 90px;
	padding-left: 0.7em;
	padding-right: 0.7em;
}

.product-ammount-col input {
	font-family: 'Montserrat';
	width: 100%;
	color: #333;
	font-size: 14px;
	font-weight: 400;
	border-radius: 3px;
	background: #f8f8fa;
	box-sizing: border-box;
	padding-left: 5px;
	height: 27px;
}

.projectpage-table {
	.available-col {
		width: 120px;
		padding-left: 0.7em;
		padding-right: 0.9em;
	}

	.manufacturer-col {
	}
}

.projects-empty {
	margin-top: 1rem;
	text-align: center;

	@media (max-width: 567px) {
		font-size: 14px;
	}
}

.projectpage-table-buttons {
	margin-top: 20px;
	gap: 20px;
	display: flex;
	justify-content: flex-end;
	// margin-left: 4rem;

	.all-price {
		display: none;

		@media (max-width: 1024px) {
			display: block;
		}
	}

	button {
		min-height: 60px;
		border: none;
		border-radius: 10px;
		cursor: pointer;
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
		border-radius: 10px;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		width: 225px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		transition: all 0.3s;

		path {
			transition: all 0.3s;
		}

		&:nth-of-type(1) {
			background: transparent;
			color: #333;
			border: 1px solid #555;
		}

		&:nth-of-type(2) {
			background: var(--main-color);
			color: white;
		}

		&:hover {
			background: #4e41ab;
			color: #fff;
			border-color: #4e41ab;

			path {
				stroke: #fff;
			}
		}

		&:first-of-type svg {
			margin-left: 10px;
		}
	}
}

.excel-button svg,
.projectpage-table-buttons button:first-of-type svg {
	margin-left: 10px;
}

.sidebar-confirm-button {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	background: var(--main-color);
	border-radius: 10px;
	box-sizing: border-box;
	height: 50px;
	padding: 0 30px;
	cursor: pointer;
	border: none;
	transition: all 0.3s;
	color: #fff;
	font-size: 15px;
	font-family: 'Montserrat';
	font-weight: 500;
	line-height: 20px;
	width: auto;
	margin-top: 35px;
	max-width: 200px;

	&:hover {
		background: #4e41ab;
	}
}

.twitter-picker {
	z-index: 55;
	top: 100%;
	margin-top: 10px;
	display: none;
	position: absolute !important;

	&.open {
		display: block;
	}
}

@media (max-width: 1500px) {
	.projectpage-header-title-buttons button {
		padding: 0% 6%;
	}
}

@media (max-width: 1200px) {
	.projectpage-header-title-buttons button {
		padding: 0% 4%;
		width: 106px;
	}
}

@media (max-width: 1024px) {
	.projectpage-table {
		display: none;
	}

	.products-mob-item__col-value {
		input {
			border-radius: 3px;
			background: #fff;
			width: 50px;
			height: 21px;
			box-sizing: border-box;
			padding-left: 5px;
		}

		.trash {
			background: none;
			border: none;
			outline: none;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 111;
			position: relative;
			padding: 10px;

			svg {
				width: 18px;
				height: 18px;
			}
		}
	}

	.project-mob .products-mob-item__row:last-child .products-mob-item__col-name {
		display: flex;
		align-items: center;
	}
}

@media (max-width: 650px) {
	.projectpage-header-title {
		flex-direction: column;
		border-bottom: none;

		h1 {
			width: 100%;
			text-align: left;
			font-size: 20px;
			line-height: 26px;
		}
	}

	.projectpage-header-title-buttons {
		max-width: 100%;
		width: 100%;

		.excel-button {
			max-width: 100%;
			width: 100%;
		}

		align-items: center;

		button:last-of-type {
		}
	}

	.excel-button {
		font-size: 80%;
		margin-top: 15px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 4% 0 !important;
		margin-right: 0 !important;
	}

	.projectpage-header-title-buttons {
		padding-right: 0;
	}

	.projectpage-search-panel {
		flex-direction: column;
		height: auto;
		gap: 10px;
	}

	.projectpage-search-panel-settings {
		height: 65px;
		width: 65px;
	}

	.projectpage-search-panel-comment {
		height: 65px;
		font-size: 80%;
	}

	.projectpage-table-buttons {
		justify-content: space-between;

		button {
			width: 48%;
			height: 65px;
			font-size: 80%;

			&:first-of-type {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}

	.projectpage-table {
		table {
			th,
			td {
				padding: 0.525em 0.125em;
				font-size: 80%;
			}
		}

		.article-col,
		.available-col {
			width: 80px;
		}

		.manufacturer-col {
			width: 100px;
		}
	}

	.projectpage-table-buttons button {
		min-height: 40px;
		height: 40px;
		border-radius: 6px;
		font-size: 10px;
		line-height: 12px;
		gap: 5px;

		svg {
			width: 13px;
			height: 13px;
			margin: 0 !important;
		}
	}

	.projectpage {
		padding-bottom: 60px;
	}

	.projectpage-search-panel-comment {
		height: 48px;
		width: 100%;
		flex: 100%;
		border-radius: 5px;

		input {
			height: 48px;
			padding-left: 15px;
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}

		button {
			height: 48px;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			width: 50px;
		}
	}

	.projectpage-search-panel {
		flex-direction: row;
		height: 100%;
		gap: 15px 10px;
		flex-wrap: wrap;
	}

	.projectpage-search-panel-settings {
		height: 40px;
		width: 80px;
		border-radius: 5px;
	}

	.projectpage-search-panel-palette button {
		width: 40px;
		height: 40px;
		border-radius: 5px;
	}

	.projectpage-search-panel svg {
		width: 20px;
		height: 20px;
	}

	.projectpage-header-title {
		gap: 15px;
	}

	.projectpage-header-title-buttons .excel-button {
		margin-top: 0;
		height: 40px;
		padding: 0 !important;
		font-size: 10px;
		line-height: 18px;

		svg {
			width: 14px;
			height: 14px;
		}
	}

	.projectpage-header {
		padding-top: 20px;
		padding-bottom: 45px;
	}

	.projectpage-search-panel-comment input {
		font-size: 9px;
		line-height: 12px;

		&::placeholder {
			font-size: 9px;
			line-height: 12px;
		}
	}

	.twitter-picker {
		width: 175px !important;
	}
}
