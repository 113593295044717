.chat {
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	// justify-content: space-between;

	button {
		cursor: pointer;
	}
}

.chat-header {
	width: 100%;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
	box-sizing: border-box;
	padding: 20px 50px;

	.select-block {
		margin-bottom: 0;
		margin-top: 10px;
	}

	select {
		padding: 10px;
		box-sizing: border-box;
		border: 1px solid #333;
		border-radius: 6px;
		color: #333;
		margin-top: 10px;
		outline: none !important;
		cursor: pointer;

		option {
			padding: 10px;
			box-sizing: border-box;
			cursor: pointer;
			background: #ebf4f8;

			&:hover,
			&:focus {
				background: #fff;
			}
		}
	}
}

.message {
	position: relative;
	margin-right: 60px;
	max-width: 900px;
	max-width: 85%;

	&::before {
		content: '';
		background-image: url(../../assets//UserChat.svg);
		width: 40px;
		height: 40px;
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		right: -50px;
		bottom: 0;
	}

	&.admin {
		&::before {
			left: -50px;
			right: auto;
		}

		margin-right: 0;
		margin-left: 60px !important;
	}
}

.chat-header {
	h1 {
		color: var(--main-color);
		font-size: 22px;
		font-weight: 500;
		line-height: 30px;
		display: flex;
		align-items: center;
		gap: 8px;
	}

	color: var(--secondary-text);
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
}

.chat-content {
	background: #f9f9fb;

	flex-grow: 1;
	padding: 1% 2%;
	padding-right: 6vw;
	overflow-y: auto;
	height: 400px;
	-ms-overflow-style: none;
	scrollbar-width: none;
	display: flex;
	flex-direction: column;
	position: relative;
	max-height: calc(100vh - 331px);
}

.chat-dialog {
	&__date {
		color: var(--secondary-text);
		text-align: center;
		@media (max-width: 768px) {
			font-size: 10px;
		}
	}
}

.chat-content-admin {
}

.chat-content {
	.loader {
		right: 50%;
	}

	&::-webkit-scrollbar {
		display: none;
	}
}

.message {
	width: auto;
	margin-top: 15px;
	margin-bottom: 15px;
	box-sizing: border-box;
	padding: 13px;
	border-radius: 13px 13px 0px 13px;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 1, 11, 0.03);
	gap: 15px;

	img {
		max-height: 377px;
		height: 100%;
		display: block;
		border-radius: 13px 13px 0px 13px;
	}

	&.file {
		margin-bottom: 0;
		padding-right: 0;
		padding-bottom: 0;
		position: relative;

		.message-info {
			background: #f9f9fb;
			position: absolute;
			top: auto;
			bottom: 10px;
			right: 7px;
			height: 22px;
			padding: 4px 8px;
		}

		&.admin {
			img {
				border-radius: 13px 13px 13px 0px;
			}

			.message-info {
				top: auto;
				align-items: center;
			}
		}
	}
}

.message__body {
	display: flex;
	align-items: center;
	gap: 15px;

	img {
		// max-width: 180px;
		width: 100%;
		height: auto;
	}
}

.message > p {
	color: #333;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	word-break: break-word;
	flex: 0 0 auto;
}

.message-info {
	display: flex;
	gap: 3px;
	align-items: center;
	border-radius: 15px;
	padding: 0;
	top: 5px;
	position: relative;
	align-self: flex-end;
	// flex: 0 0 60px;

	p {
		color: rgba(106, 106, 107, 0.7);
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;

		&:first-of-type {
			display: inline-block;
			line-height: 0;
		}
	}
}

.admin div,
.sender {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	top: 0;
}

.admin {
	align-self: flex-start;
	border-radius: 15px 15px 15px 0px;
	background: #ebf1f8;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);

	h1 {
		font-size: 14px;
		font-weight: 500;
		color: rgba(106, 106, 107, 0.7);
		margin-bottom: 8px;
		line-height: 22px;
		@media (max-width: 768px) {
			font-size: 8px;
			line-height: 16px;
			margin-bottom: 1px;
		}
	}
}

.sender {
	align-self: flex-end;
}

.chat-footer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	padding: 1% 2%;
	padding-right: 6vw;
	background-color: white;
	border: 1px solid #f6f6f6;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
	margin-top: auto;
}

.chat-footer-file,
.chat-input button {
	width: fit-content;
	height: fit-content;
	border: none;
	background: none;
}

.chat-footer-file:focus,
.chat-input button:focus {
	outline: none;
}

.chat-input-block {
	flex-grow: 1;
}

.chat-input {
	position: relative;
	flex-grow: 1;
	height: 80px;
	padding-right: 15px;
	border-radius: 15px;
	border: 1px solid #555;
	background: #f9f9fb;
	width: 100%;

	.loader {
		left: 50%;
	}
}

.bot-commands {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-top: 20px;
	display: flex;
	align-items: center;
	gap: 15px;
	flex-wrap: wrap;

	li {
		border-radius: 10px;
		border: 1px solid #555;
		padding: 13px 17px;
		box-sizing: border-box;
		color: #333;
		font-size: 16px;
		font-weight: 400;
		line-height: 18px;
		transition: all 0.3s;
		cursor: pointer;

		&:hover {
			color: #fff;
			background: #4e41ab;
			border-color: #4e41ab;
		}
	}
}

.chat-input {
	form {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	input {
		height: 60%;
		flex-grow: 1;
		background: #f8f8fa;
		border: none;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		font-family: 'Montserrat';
		padding-left: 2%;

		&:focus {
			outline: none;
		}
	}

	button {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		path {
			transition: all 0.3s;
		}

		&:hover path {
			fill: #4e41ab;
		}
	}
}

.chat button {
	path {
		transition: all 0.3s;
	}

	&:hover path {
		fill: #4e41ab;
	}
}

@media (max-width: 768px) {
	.content-chat {
		width: 92%;
	}
}

@media (max-width: 480px) {
	.content-chat {
		width: 100%;
	}

	.message {
		&::before {
			width: 30px;
			height: 30px;
			right: -40px;
		}

		margin-right: 40px;

		&.admin {
			margin-left: 40px !important;

			&::before {
				left: -40px;
			}
		}

		p {
			font-size: 10px;
			line-height: 18px;
		}
	}

	.bot-commands {
		gap: 5px;

		li {
			font-size: 10px;
			line-height: 12px;
			padding: 8px 10px;
		}
	}

	.message {
		border-radius: 10px 10px 0px 10px;
		margin-top: 5px;
		margin-bottom: 5px;

		&.admin {
			border-radius: 10px 10px 10px 0px;
		}
	}

	.chat-header {
		padding: 13px 20px;

		h1 {
			font-size: 16px;
			line-height: 20px;
		}
	}

	.chat-input {
		height: 48px;
		border-radius: 7px;

		input {
			&::placeholder {
				font-size: 10px;
				font-weight: 400;
				line-height: 18px;
			}

			font-size: 10px;
			font-weight: 400;
			line-height: 18px;
		}
	}

	.chat-header svg {
		width: 20px;
		height: 20px;
	}

	.chat-footer {
		padding: 15px 20px;
	}
}
