.wrapper {
	color: #fff;
	font-weight: 500;
	font-size: 16px;
	padding: 6px 12px;
	text-align: center;
	border-radius: 6px;

	@media (max-width: 768px) {
		padding: 6px 11px;
		font-size: 14px;
	}

	@media (max-width: 480px) {
		font-size: 10px;
		padding: 4px 8px;
	}

	&.success {
		background: #1fbd54;
	}

	&.failed {
		background: var(--red);
	}
}
