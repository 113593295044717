.dropdown {
  h2 {
    display: inline;

    &:hover {
      cursor: pointer;
    }
  }

  position: relative;
  margin-bottom: 50px;
  padding-bottom: 30px;
  padding-bottom: 0;

  &::before {
    content: '';
    position: absolute;
    left: 14px;
    top: 40px;
    height: 0;
    border-radius: 1px;
    width: 1px;
    background: rgba(218, 205, 255, 0.5);
    transition: all 0.3s;
  }

  &.opened {
    padding-bottom: 30px;

    @media (max-width:567px) {
      padding-bottom: 0;
    }
  }

  &.opened::before {
    height: calc(100% - 40px);
  }
}

.dropdown-header {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  z-index: 11;
  position: relative;

  >svg {
    display: inline;
    height: 30px;
    width: 30px;
    width: fit-content;
    margin-right: 20px;
    fill: #F8F8FA;
  }

  path {
    stroke: var(--main-color);
  }

  h2 {
    color: #333;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    transition: all 0.3s;

    &:hover {
      color: #4E41AB;
    }
  }
}

/* .dropdown-header.opened::after {
      content: url('../assets/Opened.png');
      margin-left: 10px;
      cursor: pointer;
  }
  
  .dropdown-header.closed::after {
      content: url('../assets/Closed.png');
      margin-left: 10px;
      cursor: pointer;
  } */

.dropdown-content {
  display: none;
  padding-left: 50px;
  box-sizing: border-box;

  &.open {
    display: flex;
    flex-direction: column;
  }
}

.warning-text p {
  color: var(--secondary-text);
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;
}

@media (max-width: 1060px) {
  .dropdown-content {
    padding-left: 0;
  }

  .dropdown.opened::before {
    display: none;
  }

  .dropdown-header {
    margin-bottom: 30px;
  }

  .warning-text p {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}

@media (max-width: 768px) {
  .dropdown-header {
    h2 {
      font-size: 18px;
      line-height: 26px;
    }

    >svg {
      margin-right: 15px;
    }
  }
}

@media (max-width: 480px) {
  .warning-text p {
    font-size: 10px;
    line-height: 16px;
  }

  .dropdown-header {
    h2 {
      font-size: 16px;
      font-weight: 500;
      line-height: 23px;
    }

    >svg {
      height: 18px;
      width: 18px;
      margin-right: 10px;
    }

    margin-bottom: 25px;
  }

  .dropdown {
    margin-bottom: 35px;
    padding-bottom: 0;
  }
}