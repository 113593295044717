.wrapper {
	display: flex;
	gap: 10px;
	cursor: pointer;
	box-sizing: border-box;
	color: #333;

	label {
		display: flex;
		align-items: center;
	}
	input {
		accent-color: var(--main-color);
    margin: 0 auto;
	}
}
