.suppliers {
	padding-top: 20px;
	max-width: 100%;
	width: 100%;
	padding-bottom: 80px;

	.upload-logo__preview {
		height: auto;
		color: var(--main-color);

		p {
			font-size: 18px;
		}

		@media (max-width: 480px) {
			display: flex;
		}
	}
}

.suppliers__row {
	display: flex;
	gap: 20px;
	align-items: flex-start;
}

.suppliers-filter {
	max-width: 365px;
	width: 100%;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	border-radius: 10px;
	border: 1px solid #f6f6f6;
	background: #fff;
}

.suppliers-filter__body {
	box-sizing: border-box;
	padding: 15px;
}

.suppliers-list-block {
	position: relative;
	flex: 1;
}

.slider-rating {
	padding: 10px 0;
}

.mark-item {
	display: flex;
	align-items: center;
	gap: 5px;

	svg {
		width: 15px;
		height: 15px;
	}
}

.suppliers-list-line {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.suppliers-item {
	display: flex;
	border-radius: 10px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	box-sizing: border-box;
	padding: 20px;
}

.suppliers-item__card {
	max-width: 335px;
	width: 100%;
}

.suppliers-item__desc {
	flex: 1;
}

.suppliers-item__top {
	display: flex;
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		border-radius: 1px;
		background: #ebf1f8;
		display: block;
	}
}

.suppliers-item__logo {
	padding-right: 15px;
	box-sizing: border-box;
	max-width: 95px;
	width: 100%;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		background: #ebf1f8;
		width: 1px;
		height: 39px;
	}

	img {
		width: 110px;
		height: 39px;
		object-fit: contain;
		max-width: 110px;
		width: 100%;
	}
}

.suppliers-item__country {
	display: flex;
	align-items: center;
	gap: 10px;
	flex: 1;
	padding-left: 15px;
	box-sizing: border-box;

	img {
		max-width: 100%;
		width: 59px;
		height: 39px;

		@media (max-width: 1024px) {
			width: 47px;
			height: 31px;
		}
		@media (max-width: 480px) {
			width: 43px;
			height: 28px;
		}
	}

	p {
		color: #222;
		font-size: 14px;
		font-weight: 400;

		span {
			display: block;
			margin-top: 7px;
			color: #555;
			font-size: 12px;
			font-weight: 400;
		}
	}
}

.suppliers-item__languages {
	padding-bottom: 10px;
	margin-bottom: 10px;
	position: relative;
}

.suppliers-item__languages-img {
	max-width: 100%;
	height: 20px;
	width: 30px;

	@media (max-width: 1024px) {
		width: 25px;
		height: 17px;
	}
}

.suppliers-item__languages {
	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		border-radius: 1px;
		background: #ebf1f8;
		display: block;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		gap: 13px;
		flex-wrap: wrap;
	}
}

.suppliers-item__rating {
	padding-bottom: 10px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	gap: 10px;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		border-radius: 1px;
		background: #ebf1f8;
		display: block;
	}

	.stars {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		gap: 7px;
		height: 25px;
		@media (max-width: 1024px) {
			height: 21px;
		}
		@media (max-width: 480px) {
			height: 19px;
		}
	}

	.points {
		color: #222;
		font-size: 14px;
		font-weight: 400;
	}
}

.suppliers-item__site {
	padding-bottom: 10px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	gap: 10px;
	position: relative;
	word-break: break-all;

	a {
		text-decoration: none;
	}

	span {
		flex: 0 0 22px;
	}

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		border-radius: 1px;
		background: #ebf1f8;
		display: block;
	}

	p {
		color: #222;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
	}
}

.suppliers-item__bottom {
	display: flex;
	gap: 20px;

	.time-zone {
		max-width: 140px;
		width: 100%;
		// padding-right: 20px;
		color: #222;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		box-sizing: border-box;
		position: relative;
		flex: 50%;

		&::before {
			content: '';
			position: absolute;
			right: -10px;
			top: 50%;
			transform: translateY(-50%);
			background: #ebf1f8;
			width: 1px;
			height: 39px;
		}

		span,
		a {
			color: #222;
			font-weight: 600;
			text-decoration: none;
			display: block;
			white-space: nowrap;
		}
	}

	.response-time {
		position: relative;
		flex: 50%;
		// padding-left: 20px;
		color: #222;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		box-sizing: border-box;

		span,
		a {
			color: #222;
			text-decoration: none;
			font-weight: 600;
			display: block;
			word-break: break-all;
		}
	}
}

.suppliers-item__desc {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-sizing: border-box;
	padding-left: 30px;

	p {
		color: #222;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}

	.tags {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		margin-top: 55px;

		li {
			box-sizing: border-box;
			height: 5px;
			display: flex;
			align-items: center;
			box-sizing: border-box;
			min-height: 23px;
			padding: 0 10px;
			border-radius: 5px;
			background: #333;
			color: #fff;
			font-size: 10px;
			font-weight: 500;
			line-height: 13px;
		}
	}
}

.suppliers {
	.product-searchbar-buttons {
		max-width: 187px;
		width: 100%;
	}

	.product-searchbar-button {
		width: 100%;
		gap: 10px;
		color: #fff;
		align-items: center;
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		border-radius: 0px 10px 10px 0px;
	}
}

.suppliers-list-view {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 20px;
	margin-top: 80px;
}

.view-toggle {
	display: flex;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	border-radius: 100px;
	border: 1px solid #f6f6f6;
	background: #fff;
	width: 102px;
	height: 50px;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	padding: 0 16px;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 49px;
		height: 43px;
		border-radius: 100px;
		background: #1e2321;
		transition: all 0.3s;
	}

	button {
		background: none;
		border: none;
		outline: none;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		z-index: 2;

		path,
		g {
			transition: all 0.3s;
		}
	}

	&.grid:before {
		left: 3px;
	}

	&.line:before {
		left: calc(100% - 52px);
	}

	button.active {
		path {
			fill: #fff;
		}

		g {
			opacity: 1;
		}
	}
}

.suppliers-list-grid {
	gap: 20px;
	display: flex;
	flex-wrap: wrap;

	.suppliers-item {
		width: calc(33.333% - 20px);
		flex-direction: column;
	}

	.suppliers-item__card {
		max-width: 100%;
		width: 100%;
	}

	.suppliers-item__desc {
		padding-left: 0;
		width: 100%;

		p {
			display: none;
		}

		.tags {
			margin-top: 0;
		}
	}

	.suppliers-item__bottom {
		padding-bottom: 10px;
		margin-bottom: 10px;
		position: relative;

		flex-direction: column;
		gap: 5px;

		.time-zone,
		.response-time {
			position: relative;
			max-width: 100%;
			padding-left: 0;
			padding-right: 0px;
		}

		.time-zone {
			padding-bottom: 10px;
			&::before {
				height: 1px;
				left: 0;
				right: 0;
				width: 100%;
				bottom: 0;
				top: auto;
			}
		}
		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			border-radius: 1px;
			background: #ebf1f8;
			display: block;
		}
	}
}

.suppliers-list-block .next-previous-page button {
	max-width: 100%;
	height: 60px;
}

.suppliers-filter-item {
	margin-bottom: 15px;

	&:last-child {
		margin-bottom: 0;
	}
}

.suppliers-filter-item__header {
	border-radius: 5px;
	background: #f9fafc;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 0 15px;
	color: #333;
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;
	gap: 10px;

	i {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.suppliers-filter-item__list {
	padding-top: 15px;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	display: none;

	.stars {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		gap: 1px;

		svg {
			width: 15px;
			height: auto;
		}
	}
}

.suppliers-filter-item.open {
	.suppliers-filter-item__header i {
		transform: rotate(180deg);
	}

	.suppliers-filter-item__list {
		display: block;
	}
}

.suppliers-filter-item__list {
	label {
		display: flex;
		align-items: center;
		gap: 7px;
		color: #333;
		font-size: 15px;
		font-weight: 400;
		margin-bottom: 10px;
		cursor: pointer;

		&:last-child {
			margin-bottom: 0;
		}
	}

	input {
		accent-color: var(--main-color);
	}

	.MuiSlider-thumb {
		background: var(--main-color);
		height: 20px;
		width: 20px;
	}

	.MuiSlider-track {
		background: var(--main-color);
	}

	.MuiSlider-mark {
		background: none;
	}
}

.suppliers-filter-item__sub {
	margin-bottom: 15px;
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;

	i {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.suppliers-filter-item__sub-lis {
		display: none;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.suppliers-filter-item__sub-title {
	color: #333;
	font-size: 15px;
	font-weight: 400;
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
}

.suppliers-filter-item__sub.open .suppliers-filter-item__sub-title i {
	transform: rotate(180deg);
}

.suppliers-filter-item__sub-list {
	box-sizing: border-box;
	display: none;
}

.suppliers-filter-item__sub.open .suppliers-filter-item__sub-list {
	padding-left: 15px;
	padding-top: 15px;
	display: block;
}

// .open .suppliers-filter-item__sub-list {
// 	display: block;
// }

.suppliers-filter-item__search {
	position: relative;
	margin-bottom: 10px;
	width: 100%;

	input {
		border-radius: 5px;
		border: 1px solid #555;
		background: #fff;
		outline: none;
		box-sizing: border-box;
		padding-left: 15px;
		height: 37px;
		color: var(--secondary-text);
		font-family: 'Montserrat';
		font-size: 12px;
		font-weight: 400;
		line-height: 17px;
		width: 100%;

		&::placeholder {
			color: var(--secondary-text);
			font-family: 'Montserrat';
			font-size: 12px;
			font-weight: 400;
			line-height: 17px;
		}
	}

	button {
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		background: none;
		border: none;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.3s;

		path {
			transition: all 0.3s;
		}

		&:hover path {
			stroke: #4e41ab;
		}
	}
}

.button-filter-mob {
	display: none;
}

.manufacturers-more {
	background: none;
	border: none;
	outline: none;
	display: flex;
	align-items: center;
	gap: 3px;
	outline: none;
	transition: all 0.3s;
	cursor: pointer;
	color: var(--main-color);
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;

	path {
		transition: all 0.3s;
	}

	&:hover {
		color: #4e41ab;

		path {
			stroke: #4e41ab;
		}
	}
}

.filter-manufacturers-list {
	&.full .manufacturers-more svg {
		transform: rotate(180deg);
	}

	max-height: 305px;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 3px;
	}

	label {
		display: none;

		&:nth-child(-n + 10) {
			display: flex;
		}
	}

	&.full label {
		display: flex;
	}
}

.rest-filter {
	background: none;
	height: 50px;
	border: 1px solid #555;
	max-width: 100%;
	color: #333;

	&:hover {
		color: #fff;
		border-color: #4e41ab;
	}
}

@media (max-width: 1440px) {
	.suppliers-list-grid {
		gap: 20px;

		.suppliers-item {
			width: calc(33.333% - 14px);
		}
	}

	.suppliers__row {
		gap: 20px;
	}

	.suppliers-item {
		padding: 20px;
	}

	.suppliers-item__languages {
		svg {
			width: 28px;
			height: 19px;
		}

		ul {
			gap: 8px;
		}
	}

	.suppliers-item__bottom {
		.time-zone {
			max-width: 100%;
		}

		.response-time {
			width: 100%;
		}
	}

	.suppliers-item__country p {
		font-size: 13px;

		span {
			font-size: 11px;
		}
	}

	.suppliers-item__desc {
		.tags {
			margin-top: 30px;
		}

		p {
			font-size: 15px;
			line-height: 23px;
		}
	}
}
@media (max-width: 1310px) {
	.suppliers-list-grid {
		gap: 20px;

		.suppliers-item {
			width: calc(50% - 10px);
		}
	}
}

@media (max-width: 1200px) {
	.suppliers-list-grid .suppliers-item {
		width: calc(50% - 10px);
	}

	.suppliers-filter {
		max-width: 359px;
		width: 100%;
	}

	.suppliers-item__desc {
		padding-left: 20px;

		p {
			font-size: 12px;
			font-weight: 400;
			line-height: 20px;
		}
	}

	.suppliers-item__card {
		max-width: 300px;
		width: 100%;
	}
}

.suppliers-filter__header {
	display: none;
}

@media (max-width: 1024px) {
	.button-filter-mob {
		display: block;
	}

	.suppliers .product-searchbar-button:first-child {
		border-radius: 0;
		font-size: 0;
	}

	.suppliers-filter {
		position: fixed;
		left: -200%;
		top: 0;
		height: 100vh;
		z-index: 55;
		max-width: 400px;
		transition: all 0.3s;
		border: none;

		&.open {
			left: 0;
		}
	}

	.suppliers-filter__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: var(--main-color);
		color: #fff;
		font-size: 18px;
		font-weight: 500;
		line-height: 25px;
		box-sizing: border-box;
		padding: 20px 30px;
	}

	.suppliers-filter__body {
		padding: 0 30px;
		padding-top: 15px;
		padding-bottom: 100px;
		overflow-y: auto;
		height: 100%;

		&::-webkit-scrollbar {
			width: 4px;
		}
	}

	.suppliers-list-grid .suppliers-item {
		width: calc(33.333% - 14px);
	}

	.suppliers-item__languages svg {
		width: 25px;
		height: 17px;
	}

	.suppliers-item__bottom {
		.response-time,
		.time-zone {
			font-size: 10px;
			line-height: 16px;
		}
	}

	.suppliers-item__site p {
		font-size: 12px;
		line-height: 18px;
	}

	.suppliers .product-searchbar-button {
		gap: 0px;
	}
}

@media (max-width: 768px) {
	.suppliers-list-grid .suppliers-item {
		width: calc(50% - 10px);
	}
}

@media (max-width: 480px) {
	.suppliers {
		padding-bottom: 60px;
	}

	.suppliers-list-grid {
		.suppliers-item {
			width: 100%;
		}

		gap: 15px;
	}

	.suppliers-item {
		flex-direction: column;
		padding: 15px;
	}

	.suppliers-item__desc {
		padding-left: 0;
		margin-top: 0;

		.tags {
			margin-top: 10px;
		}
	}

	.suppliers .product-searchbar-buttons {
		.product-searchbar-button:nth-child(2) {
			display: flex;
		}

		max-width: 85px;
		width: 100%;
	}

	.suppliers-list-view {
		display: none;
	}

	.suppliers-list-block {
		margin-top: 60px;
	}

	.suppliers-item__desc p {
		display: none;
	}

	.suppliers-list-block .next-previous-page {
		margin-top: 15px;

		button {
			height: 40px;
			font-size: 14px;
			line-height: 23px;
		}
	}

	.suppliers-filter__header {
		padding: 20px;
	}

	.suppliers-filter__body {
		padding: 0 20px;
		padding-top: 15px;
		padding-bottom: 100px;
	}

	.suppliers-item__card {
		max-width: 100%;
	}
}
