.wrapper {
}

.firstWrapper {
	border: 1px solid #f6f6f6;
	box-shadow: 0px 2px 14px 0px #0c0b0b0d;
	border-bottom-right-radius: 100px;
	border-bottom-left-radius: 100px;
	height: 665px;
	position: relative;
	overflow: hidden;
}

.first {
	display: flex;
	align-items: center;
	gap: 10px;
	height: 100%;

	.left {
		flex: 0 0 66%;
		position: relative;
		z-index: 1;

		h1 {
			font-size: 48px;
			font-weight: 700;
			text-transform: uppercase;
			line-height: 56px;
			span {
				color: var(--main-color);
			}
		}

		p {
			margin-top: 20px;
		}

		.button {
			margin-top: 90px;
			display: flex;
			align-items: center;
			gap: 20px;

			button {
				max-width: 347px;
			}
			.info {
				color: var(--secondary-text);
				font-size: 16px;
				span {
					color: var(--green);
				}
			}
		}
	}
	.right {
		img {
			position: absolute;
			right: -2%;
			top: 0;
			bottom: 0;
		}
	}
}

.section {
	margin-top: 100px;

	.rowTitle {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 50px;

		.description {
			color: var(--secondary-text);
			font-weight: 400;
			max-width: 516px;
			text-align: right;
		}
	}
	.title {
		font-size: 32px;
		font-weight: 700;
		color: #222222;
		text-transform: uppercase;
		line-height: 46px;
		span {
			color: var(--main-color);
		}
	}
}

.two {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 35px;

	.card {
		box-shadow: 0px 2px 14px 0px #0c0b0b0d;

		border: 1px solid #f6f6f6;
		border-radius: 20px;
		padding: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		.name {
			color: #222222;
			font-size: 22px;
			font-weight: 600;
		}

		.list {
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			gap: 7px;

			.item {
				border-radius: 5px;
				background: #f0f2fb;
				color: #333333;
				padding: 6px 15px;
			}
		}

		.image {
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}

	> div {
		&:last-child {
			grid-column: span 2;
		}
	}
}

.three {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;

	.card {
		box-shadow: 0px 2px 14px 0px #0c0b0b0d;

		border: 1px solid #f6f6f6;
		border-radius: 20px;
		padding: 35px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		min-height: 350px;

		transition: all 0.3s ease 0s;
		@media (any-hover: hover) {
			&:hover {
				transform: translateY(-20px);
				background: linear-gradient(132.05deg, #8a7de7 0%, #2b2173 105.42%);
				color: #fff;

				.name {
					color: #fff;
				}
				.item {
					&::after {
						background: #fff;
					}
				}

				.image {
					opacity: 0.3;
					path {
						fill: #8a7de74d;
					}
				}
			}
		}

		.info {
			position: relative;
			z-index: 2;
		}
		.name {
			color: #222222;
			font-size: 22px;
			font-weight: 600;
		}

		.list {
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			gap: 5px;

			.item {
				position: relative;
				padding-left: 17px;

				&::after {
					content: '';
					position: absolute;
					width: 8px;
					height: 8px;
					border-radius: 100%;
					background: var(--main-color);
					left: 0;
					top: 7px;
				}
			}
		}

		.image {
			height: 150px;
			position: absolute;
			right: 40px;
			bottom: -5px;
		}
	}
}

.wrapperFull {
	padding: 100px 50px;
	border-radius: 50px;
	background: linear-gradient(132.05deg, #8a7de7 0%, #2b2173 105.42%);
	color: #fff;

	&.contactWrapper {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		.wrapperInfo {
			align-items: center;
		}
	}

	.wrapperInfo {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 114px;

		.mainInfo {
			display: flex;
			flex-direction: column;
			font-weight: 400;
			.name {
				font-size: 32px;
				font-weight: 700;
				margin-bottom: 20px;
				text-transform: uppercase;
			}

			p {
				font-weight: 400;
				span {
					font-weight: 500;
				}
			}

			.actions {
				font-weight: 400;
				margin-top: auto;
				display: flex;
				align-items: center;
				gap: 17px;

				button {
					color: var(--main-color);
					font-weight: 400;
					max-width: 299px;
					@media (any-hover: hover) {
						&:hover {
							background: #fff;
						}
					}
				}
			}
		}

		.secondInfo {
			.secondList {
				display: flex;
				flex-direction: column;
				gap: 50px;

				.list {
					img {
						width: 100%;
					}
				}
				.item {
					.name {
						b {
							font-size: 38px;
						}
						font-size: 22px;
					}
					.list {
						margin-top: 15px;
						border-radius: 20px;
						border: 1px solid #dae0fc;
						box-shadow: 0px 2px 14px 0px #0c0b0b0d;
						padding: 30px 0;
					}
				}
			}
		}

		.secondBenefits {
			.secondList {
				display: flex;
				flex-direction: column;
				gap: 20px;
				.item {
					padding: 10px;
					border-radius: 20px;
					border: 1px solid #f6f6f6;
					box-shadow: 0px 2px 14px 0px #0c0b0b0d;
					background: #ffffff1a;
					display: flex;
					align-items: center;
					gap: 17px;
					transition: all 0.3s ease 0s;

					@media (any-hover: hover) {
						&:hover {
							background: #fff;
							transform: translateX(-20px);

							.icon {
								background: var(--main-color);
							}
							.name {
								color: var(--main-text);
							}

							.listItem {
								background: #f0f2fb;
							}
						}
					}
					.icon {
						width: 90px;
						height: 90px;
						padding: 17px;
						border-radius: 17px;
						background: #fff;
						display: flex;
						align-items: center;
						justify-content: center;
					}
					.name {
						font-size: 20px;
						font-weight: 600;
					}
					.list {
						margin-top: 13px;
						display: flex;
						align-items: center;
						gap: 7px;
						flex-wrap: wrap;
						border-radius: 5px;
						font-weight: 400;
					}
					.listItem {
						border-radius: 5px;
						font-weight: 400;
						padding: 6px 15px;
						background: #fff;
						color: var(--main-text);
					}
				}
			}
		}
	}
}

.contactWrapper {
	.wrapperInfo {
		gap: 10px;
		grid-template-columns: 70% 30%;
	}

	.contact {
		p {
			margin-top: 20px;
			color: #fff;
			a {
				color: #fff;
			}
		}
	}
	.formContact {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		gap: 10px;

		button {
			height: 58px;
		}
	}
}

.four {
	display: flex;
	flex-direction: column;
	gap: 30px;

	.card {
		box-shadow: 0px 2px 14px 0px #0c0b0b0d;

		border: 1px solid #f6f6f6;
		border-radius: 20px;
		padding: 21px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		display: grid;
		grid-template-columns: 1fr 1fr;

		.row {
			display: flex;
			gap: 10px;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 20px;

			.bigTitle {
				display: flex;
				gap: 15px;
				align-items: center;
			}
			.name {
				background: var(--main-color);
				border-radius: 55px;
				padding: 6px 20px;
				color: #fff;
				font-weight: 400;
				span {
					font-weight: 700;
				}
			}

			.inDev {
				font-size: 14px;
				font-weight: 400;
				color: var(--green);
			}

			.navigate {
				width: 34px;
				height: 34px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				background: var(--main-color);
				border-radius: 100%;
				cursor: pointer;

				&.disabled {
					opacity: 0.3;
					cursor: default;
				}
			}
		}

		.title {
			color: #222222;
			font-size: 20px;
			font-weight: 600;
			margin-bottom: 10px;
		}
		.description {
			color: #222222;
		}

		.list {
			margin-top: 30px;
			display: flex;
			flex-wrap: wrap;
			gap: 9px;

			.item {
				position: relative;
				padding: 6px 15px;
				display: flex;
				align-items: center;
				gap: 7px;
				font-size: 15px;
				font-weight: 400;
				border-radius: 5px;
				background: #f0f2fb;
			}
		}

		.image {
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
}

.tariffFirst {
	.tariffList {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;

		:global {
			.tariff {
				width: 100%;
			}
		}
	}
	.cardTariff {
		border-radius: 20px;
		background: #fff;
		padding: 25px;
		display: flex;
		flex-direction: column;
		position: relative;
	}

	.cardTitle {
		font-size: 21px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 15px;
		span {
			color: var(--main-color);
		}
	}

	.cardDescription {
		font-size: 14px;
		color: #222;
		line-height: 20px;
		p {
			font-weight: 400;
		}
		span {
			color: var(--main-color);
		}
	}

	.actions {
		margin-top: auto;
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;

		.info {
			color: var(--secondary-text);
			font-size: 13px;
			height: 20px;
			span {
				color: var(--green);
			}
		}
	}
}

.five {
	display: flex;
	gap: 20px;
	overflow: auto;
	padding-bottom: 10px;
	justify-content: space-between;
	.card {
		background: #fff;
		box-shadow: 0px 2px 14px 0px #0c0b0b0d;
		border: 1px solid #f6f6f6;
		border-radius: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 110px;
		transition: all 0.3s ease 0s;
		width: 250px;
		flex: 0 0 250px;

		&::after {
			content: '';
		}
	}
}
