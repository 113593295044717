.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	background: var(--main-color);
	border-radius: 10px;
	box-sizing: border-box;
	height: 50px;
	padding: 0 30px;
	cursor: pointer;
	border: none;
	transition: all 0.3s;
	color: #fff;
	font-size: 15px;
	font-family: 'Montserrat';
	font-weight: 500;
	line-height: 20px;
	min-width: 200px;
	width: 100%;
	transition: all 0.3s ease 0s;
	font-size: 16px;

	&.landing {
		border: 1px solid #555;
		color: #333;
		transition: all 0.3s;
		background: #fff;
		min-width: auto;
		font-weight: 400;
		color: var(--main-color);
		&:hover {
			background: #f4f4f4;
		}
	}

	&.outline {
		border: 1px solid #555;
		color: #333;
		transition: all 0.3s;
		background: #fff;
		min-width: auto;

		&:hover {
			color: #fff;
			background: var(--main-color);
			border-color: var(--main-color);
		}
	}

	a {
		height: 100%;
		color: #fff;
		width: 100%;
		display: block;
		text-decoration: none;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&:hover {
		background: #4e41ab;
	}

	&.small {
		height: 30px;
		padding: 0 20px;
		font-size: 14px;
		line-height: 100%;
		border-radius: 6px;
		min-width: auto;
	}

	&.large {
		height: 58px;
	}

	@media (max-width: 480px) {
		font-size: 13px;
		line-height: 16px;
		height: 40px;
		border-radius: 6px;
	}
}
