.warning-text {
	margin: 0;
	padding: 0;
	width: 100%;
	margin-bottom: 30px;
}

/* .warning-text p {
      color: var(--secondary-text);
      font-family: 'Montserrat';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 10px;
  } */

.partnerrequest-button {
	max-width: 100%;
	margin-top: 0px;
	margin-left: 50px;
	max-width: 710px;
	width: 100%;
}
.workDays-container {
	p {
		margin: 10px 0;
	}
	> div {
		display: flex;
		align-items: center;
		gap: 30px;
	}

	.workDays-table {
		width: 100%;
		border-collapse: collapse;
		margin: 20px 0;
		font-size: 16px;
		font-family: Arial, sans-serif;

		th,
		td {
			padding: 12px 15px;
			border: 1px solid #ddd;

			@media (max-width: 510px) {
				padding: 12px 0;
				font-size: 12px;
			}
		}

		th {
			background-color: var(--main-color);
			&:first-child {
				text-align: left;
				@media (max-width: 510px) {
					padding-left: 5px;
				}
			}
			color: white;
			text-align: center; // Центрирование заголовков
		}

		tr {
			&:nth-child(even) {
				background-color: #f2f2f2; // Светло-серый цвет для четных строк
			}

			&:hover {
				background-color: #ddd; // Цвет при наведении на строку
			}
		}

		td {
			background-color: #fff; // Белый цвет для ячеек
			text-align: center; // Центрирование текста по умолчанию

			// Выравнивание первой ячейки по левому краю
			&:first-child {
				text-align: left;
				@media (max-width: 510px) {
					padding-left: 5px;
				}
			}
		}
	}
}

.partners-container {
	padding-top: 0 !important;

	@media (max-width: 1060px) {
		margin-top: -20px;
	}

	@media (max-width: 480px) {
		margin-top: 0;
	}
}

.terms-form .dropdown:last-of-type {
	// margin-bottom: 0;
}

@media (max-width: 1060px) {
	.partners-container {
		padding-top: 80px;
	}

	.partnerrequest-button {
		margin-left: 0;
	}
}

@media (max-width: 480px) {
	.partners-container {
		padding-top: 90px;
	}

	.partnerrequest-button {
		margin-top: 35px;
	}
}
