.recovery-form {
    width: 450px;
    border-radius: 13px;
    background: white;
    margin: 20px;
    padding: 35px;
  
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    h1 {
      text-align: center;
      font-weight: 500;
      font-size: 25px;
    }
  
    p {
      font-weight: 500;
      margin: 10px 0 30px;
      text-align: center;
    }
  
    input {
      border: 1px solid var(--main-color);
      border-radius: 10px;
      height: 40px;
      width: 90%;
      margin-top: 15px;
      padding: 0% 10px;
      font-family: "Montserrat";
      font-size: 80%;
    }
  
    button {
      width: 90%;
      height: 40px;
      margin: 15px 0;
      background: var(--main-color);
      box-shadow: 0px 2px 10px rgba(12, 11, 11, 0.05);
      border-radius: 10px;
      border: none;
      font-size: 80%;
      font-family: "Montserrat";
      color: white;
    }
  }
  
  @media (max-width: 483px) {
    .recovery-form {
      width: 90%;
      padding: 5%;
      margin: auto;
  
      h1 {
        font-size: calc((100vw)/(1280 - 680) * (24 - 16) + 14px);
      }
  
      p {
        font-size: calc((100vw - 480px)/(1280 - 480) * (24 - 16) + 12px);
        margin: 5px 0 15px;
      }
  
      input {
        height: 30px;
        margin-top: 10px;
        font-size: 60%;
        width: 80%;
      }
  
      button {
        width: 70%;
      }
    }
  }
  