.wrapper {
	position: relative;

	&.isOpen {
		.icon {
			color: var(--main-color);
			transform: rotate(180deg);
		}

		.list,
		.listWrapper {
			display: flex;
		}
	}
	.icon {
		transition: all 0.3s ease 0s;
		cursor: pointer;
	}

	.flex {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 5%;
		flex-direction: row;
	}

	.listWrapper {
		display: none;
		max-height: 300px;
		border-radius: 4px;
		top: 34px;
		position: absolute;
		background-color: hsl(0, 0%, 100%);
		box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
		gap: 10px;
		padding: 10px 2px 5px 2px;
		flex-direction: column;
	}
	.list {
		width: 100%;
		flex-direction: column;
		gap: 4px;
		justify-content: flex-start;
		overflow: auto;
		.item {
			padding: 5px;
			width: 100%;
			border-bottom: 1px solid #ccc;
			&:last-child {
				border: none;
			}

			:global {
				.sidebar-nav-options {
					padding-left: 0;
				}
			}
		}
	}
}
