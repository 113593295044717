.select-language {
	cursor: pointer;

	&.open {
		path {
			stroke: #fff;
		}
	}
}

.selected-result {
	border-radius: 5px;
	border: 1px solid var(--main-color);
	width: 38px;
	height: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.3s;

	img,
	svg {
		width: 30px;
		height: 25px;
	}

	&:hover {
		background: #4e41ab;
		border-color: #4e41ab;

		path {
			stroke: #fff;
		}
	}
}

.select-language.open .selected-result {
	background: #4e41ab;
	border-color: #4e41ab;
}

.languages-options {
	position: absolute;
	background: #fff;
	border-radius: 10px;
	box-sizing: border-box;
	padding: 20px;
	top: 100%;
	transform: translateY(10px);
	flex-direction: column;
	gap: 15px;
	z-index: 66;
	max-height: 385px;
	overflow: hidden;
	overflow-y: auto;
	display: none;

	&::-webkit-scrollbar {
		width: 3px;
	}

	&::-webkit-scrollbar-track {
		background: #ebeaed;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background: var(--main-color);
	}

	scrollbar-width: thin;
	scrollbar-color: var(--main-color) #ebeaed;

	scrollbar-track-color: #ebeaed;
	scrollbar-thumb-color: var(--main-color);
}

.language-option {
	display: flex;
	gap: 10px;
	color: #333;
	font-family: Montserrat;
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
	text-decoration: none;
	align-items: center;
	display: flex;
	gap: 10px;
	// justify-content: space-between;

	svg,
	path {
		width: 13px;
		height: 100%;
	}
	path,
	circle {
		stroke: #333 !important;
	}

	img {
		width: 33px;
		height: 23px;
	}

	&:hover {
		color: #4e41ab;
	}
}

.select-language.open .languages-options {
	display: flex;
	box-shadow: 1px 10px 10px #ccc;
}

.language-select__placeholder {
	display: none;
}

@media (max-width: 480px) {
	.selected-result {
		width: 35px;
		height: 35px;

		img {
			width: 21px;
			height: 15px;
		}
	}

	.language-option {
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;

		img {
			width: 26px;
			height: 16px;
		}
	}

	.languages-options {
		padding: 15px;
		max-height: 385px;
	}
}
